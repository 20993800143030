import { loadStripe } from '@stripe/stripe-js';
import DevModeHelper from './DevModeHelper';

global.devMode = DevModeHelper.devMode;

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

console.log(process.env)

console.log("New Stripe instance")

export default {
    getNewStripeInstance: () => {
        return stripe;
    }
}