import BigNumber from 'big-number';
import { Numeric, Serialize } from 'eosjs';
import CatalogHelper from './CatalogHelper';

import Packs from './packs';

import Config from '../config/config';
import config from '../config/config';

export const { packs, contracts } = Config;

export const UNPACKING_CONTRACT = contracts.UNPACKING;
export const TOKEN_CONTRACT = contracts.TOKEN;
export const SOCIAL_CONTRACT = contracts.SOCIAL;
export const WISHLIST_CONTRACT = contracts.WISHLIST;


export const TOKEN_NAMES = Config.packs.TOKEN_NAMES
export const PACK_TYPES = Config.packs.PACK_TYPES

const VALID_AUTHORS = {}

for (let i = 0; i < Config.validation.AUTHORS.length; i++) {
	VALID_AUTHORS[Config.validation.AUTHORS[i]] = true;
}

export const initialPacks = Packs.map(pack => {
	return ({ balance: "0 " + pack.token });
});

export const UNBOXING_STATUS = {
	PENDING: 'pending',
	CREATING: 'creating',
	DONE: 'done',
	ALREADY_UNPACKED: 'already_unpacked'
}

export const numericFromName = (accountName) => {
	const sb = new Serialize.SerialBuffer({
		textEncoder: new TextEncoder(),
		textDecoder: new TextDecoder()
	})

	sb.pushName(accountName)

	return Numeric.binaryToDecimal(sb.getUint8Array(8));
}

let getInventoryByLowerBound = (accountName, lower_bound = "") => {
	return new Promise((resolve, reject) => {
		global.wax.rpc.get_table_rows({
			json: true,
			code: 'simpleassets',
			scope: accountName,
			table: 'sassets',
			lower_bound: lower_bound,
			limit: 100,
		}).then(async result => {
			resolve(result)
		}).catch(err => {
			reject(err)
		});
	});
}

function getDelegated() {
	return new Promise((resolve, reject) => {
		global.wax.rpc.get_table_rows({
			json: true,
			code: 'simpleassets',
			scope: "simpleassets",
			table: 'delegates',
			limit: 100,
		}).then(result => {
			let delegated = [];
			if (result && result.rows && result.rows.length > 0) delegated = result.rows.map(row => row.assetid);
			resolve(delegated)
		}).catch(err => {
			reject(err)
		});
	})
}

const getPendingNfts = (accountName, unboxingID) => {
	return new Promise((resolve, reject) => {
		let lb = parseInt(unboxingID) * Math.pow(10, 3)
		global.wax.rpc.get_table_rows({
			json: true,
			code: UNPACKING_CONTRACT,
			scope: accountName,
			table: 'pendingnft.a',
			lower_bound: lb,
			upper_bound: lb + 100,
			limit: 100,
		}).then(result => resolve(result.rows)).catch(err => {
			reject(err)
		});
	});
}

function shuffle(a) {
	for (let i = a.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[a[i], a[j]] = [a[j], a[i]];
	}
	return a;
}


export const getUnboxingStatus = async (accountName, unboxingID) => {
	const rngjob = await global.wax.rpc.get_table_rows({
		json: true,
		code: UNPACKING_CONTRACT,
		scope: UNPACKING_CONTRACT,
		table: 'rngjobs',
		lower_bound: unboxingID,
		upper_bound: unboxingID + 1,
		limit: 100,
	}).then(x => x.rows);

	/*only happens if the rngjob doesn exist yet*/
	if (!rngjob[0]) {
		await new Promise(r => setTimeout(r, 100));
		return await getUnboxingStatus(accountName, unboxingID);
	}

	if (rngjob[0].status === UNBOXING_STATUS.PENDING) {
		return {
			pendingNFts: [],
			status: UNBOXING_STATUS.PENDING
		}
	} else {
		const pendingNfts = await getPendingNfts(accountName, unboxingID);
		if (pendingNfts.length) {
			return {
				pendingNfts,
				status: UNBOXING_STATUS.CREATING
			}
		} else {
			return {
				pendingNFts: [],
				status: UNBOXING_STATUS.DONE
			}
		}
	}
}

async function _createnfts(accountName, unboxingID, ids) {
	const receipt = await global.wax.api.transact({
		actions: [
			{
				account: UNPACKING_CONTRACT,
				name: 'getcards',
				authorization: [{
					actor: accountName,
					permission: 'active',
				}],
				data: {
					from: accountName,
					unboxing: unboxingID,
					cardids: ids.map(x => x.id)
				},
			}
		]
	}, {
		blocksBehind: 15,
		expireSeconds: 3600,
	});

	const nfts = receipt.processed.action_traces[0].inline_traces.map(x => {
		return x.inline_traces[1].act.data
	});

	return nfts;
}

export const createnfts = async (accountName, unboxingID, max = 55, unboxingStatus) => {

	if (!unboxingStatus) {
		unboxingStatus = await getUnboxingStatus(accountName, unboxingID);
		unboxingStatus.unboxedNfts = [];
	}

	if (unboxingStatus.status === UNBOXING_STATUS.CREATING) {
		const shuffledNfts = shuffle(unboxingStatus.pendingNfts).filter(({ done }) => !done);
		if (shuffledNfts.length == 0) return {
			pendingNFts: [],
			unboxedNfts: [],
			status: UNBOXING_STATUS.ALREADY_UNPACKED
		}
		const results = [];
		try {
			results.push(await _createnfts(accountName, unboxingID, shuffledNfts));
		} catch (e) {
			while (shuffledNfts.length) {
				const chosenNfts = shuffledNfts.splice(0, max);
				results.push(await _createnfts(accountName, unboxingID, chosenNfts))
			}
		}
		const nfts = [];
		for (let i = 0; i < results.length; i++) {
			for (let j = 0; j < results[i].length; j++) {
				nfts.push(results[i][j]);
			}
		}

		return {
			...unboxingStatus,
			unboxedNfts: nfts
		}
	} else {
		return unboxingStatus
	}
}

export const getBoxType = async (accountName, unboxingID) => {
	return global.wax.rpc.get_table_rows({
		json: true,
		code: UNPACKING_CONTRACT,
		scope: accountName,
		table: 'pendingnft.a',
		lower_bound: unboxingID * 1000,
		upper_bound: unboxingID * 1000 + 1000,
		limit: 100,
	}).then(x => x.rows[0]['boxtype']);
}

export const unbox = async (accountName, pack) => {
	let packType = pack.type
	if (!PACK_TYPES[packType] || !packType)
		throw "invalid pack type";


	const actions = [

		{
			account: UNPACKING_CONTRACT,
			name: 'unbox',
			authorization: [{
				actor: accountName,
				permission: 'active',
			}],
			data: {
				from: accountName,
				type: packType
			},
		}
	];

	actions.unshift({
		account: TOKEN_CONTRACT,
		name: 'transfer',
		authorization: [{
			actor: accountName,
			permission: 'active',
		}],
		data: {
			from: accountName,
			to: UNPACKING_CONTRACT,
			quantity: `1 ${TOKEN_NAMES[packType]}`,
			memo: ''
		},
	})
	if (pack.wd_required) {
		let act = {
			account: "trade",
			name: 'withdraw',
			authorization: [{
				actor: accountName,
				permission: 'active',
			}],
			data: {
				owner: accountName,
				nfts: [],
				fts: [`1 ${pack.token}`].map(ftToAssetEx)
			},
		}
		await global.wax.api.transact({
			actions: [act]
		}, {
			blocksBehind: 10,
			expireSeconds: 120,
		})
	}
	const receipt = await global.wax.api.transact({
		actions
	}, {
		blocksBehind: 10,
		expireSeconds: 120,
	});

	return receipt;
}

export const withdrawNFTs = nfts => {
	return new Promise(async resolve => {
		let accountName = global.wax.userAccount;
		if (typeof nfts === "string") nfts = [nfts];
		let acts = [{
			account: "trade",
			name: 'withdraw',
			authorization: [{
				actor: accountName,
				permission: 'active',
			}],
			data: {
				owner: accountName,
				nfts: nfts,
				fts: []
			},
		},
			{
				account: 'simpleassets',
				name: 'claim',
				authorization: [{
					actor: accountName,
					permission: 'active',
				}],
				data: {
					"claimer": accountName,
					"assetids": nfts,
				}
			}]
		let result = await global.wax.api.transact({
			actions: acts
		}, {
			blocksBehind: 10,
			expireSeconds: 120,
		});
		resolve(result)
	})
}

export const getInventory = (accountName, temp = [], lower_bound = "") => {
	return new Promise(async (resolve, reject) => {
		let inv = await getInventoryByLowerBound(accountName, lower_bound);
		temp = temp.concat(inv.rows)
		if (inv.more) {
			let new_lower_bound = inv.rows[inv.rows.length - 1].id;
			resolve(await getInventory(accountName, temp.filter(({ id }) => id !== new_lower_bound), new_lower_bound));
		} else {
			let delegated = await getDelegated();
			temp = temp.filter(nft => VALID_AUTHORS[nft.author] === true);
			temp = temp.map(nft => {
				try {
					nft.mdata = JSON.parse(nft.mdata)
				} catch (e) {
				}
				try {
					nft.idata = JSON.parse(nft.idata)
				} catch (err) {
				}
				if (!nft.idata) nft.idata = {};
				nft.mdata = {
					...nft.idata,
					...nft.mdata
				}

				let name;
				if (nft.mdata && nft.mdata.name) name = nft.mdata.name;
				nft.name = name;
				nft.delegated = delegated.includes(nft.id);
				return nft;
			});
			temp.map(nft => nft != null)
			resolve(temp);
		}
	});
}

export const idsToConditions = ids => {
	let conds = [];
	for (let i = 0; i < ids.length; i++) {
		let id = ids[i]
		conds.push({
			"field_0": 1,
			"field_1": i + 1,
			"field_2": {
				"key": "author",
				"operation": "=",
				"value": UNPACKING_CONTRACT,
			}
		})
		conds.push({
			"field_0": 1,
			"field_1": i + 1,
			"field_2": {
				"key": "assettype",
				"operation": "=",
				"value": "0"
			}
		})
		conds.push({
			"field_0": 1,
			"field_1": i + 1,
			"field_2": {
				"key": "id",
				"operation": "=",
				"value": id
			}
		})
	}
	return conds;
}

export const ftsToConditions = (fts, lastBoxId = 0) => {
	let conds = [];
	for (let i = 0; i < fts.length; i++) {
		let ft = fts[i]
		conds.push({
			"field_0": 1,
			"field_1": i + lastBoxId + 1,
			"field_2": {
				"key": "author",
				"operation": "=",
				"value": TOKEN_CONTRACT,
			}
		})
		conds.push({
			"field_0": 1,
			"field_1": i + lastBoxId + 1,
			"field_2": {
				"key": "assettype",
				"operation": "=",
				"value": "2"
			}
		})
		conds.push({
			"field_0": 1,
			"field_1": i + lastBoxId + 1,
			"field_2": {
				"key": "quantity",
				"operation": "=",
				"value": ft
			}
		})
	}
	return conds;
}

export const offerTrade = (to, sendingNfts, sendingFts, receivingNfts, receivingFts, memo, topropid = 0) => {

	// IDS that are being sent
	let nfts_to_send = sendingNfts.map(nft => nft.id);

	let fts_to_send = sendingFts.map(ft => {
		return {
			author: TOKEN_CONTRACT,
			quantity: ft.quantity,
			assettype: 2,
		}
	});
	let fts_to_transfer = sendingFts.filter(ft => ft.deposit).map(ft => ft.deposit)

	// NFTs that need to be deposited
	let nfts_to_transfer = sendingNfts.filter(nft => !nft.in_wet).map(nft => nft.id)

	// Conditions
	let conds = idsToConditions(receivingNfts.map(nft => nft.id));
	let lastBoxId = receivingNfts.length;

	conds = [
		...conds,
		...ftsToConditions(receivingFts, lastBoxId)
	]

	let acts = [];
	if (nfts_to_transfer.length > 0 || fts_to_transfer.length > 0 || receivingFts.length > 0) acts = [
		{
			account: 'trade',
			name: "enabletoken",
			authorization: [{
				actor: global.wax.userAccount,
				permission: 'active',
			}],
			data: {
				owner: global.wax.userAccount,
				nfts: nfts_to_transfer,
				fts: [
					...fts_to_transfer.map(ftToAssetEx),
					...receivingFts.map(ftToAssetEx)
				],
			},
		},
		nfts_to_transfer.length > 0 ? {
			account: 'simpleassets',
			name: "transfer",
			authorization: [{
				actor: global.wax.userAccount,
				permission: 'active',
			}],
			data: {
				from: global.wax.userAccount,
				to: "trade",
				assetids: nfts_to_transfer,
				memo: "",
			},
		} : null,
		...fts_to_transfer.map(ft => ({
			account: TOKEN_CONTRACT,
			name: "transfer",
			authorization: [{
				actor: global.wax.userAccount,
				permission: 'active',
			}],
			data: {
				from: global.wax.userAccount,
				to: "trade",
				quantity: ft,
				memo: "",
			},
		})),

	];

	if (conds.length === 0) {
		// One way gift (outgoing)
		acts = [
			...acts,
			{
				account: 'trade',
				name: 'creategift',
				authorization: [{
					actor: global.wax.userAccount,
					permission: 'active',
				}],
				data: {
					"owner": global.wax.userAccount,
					"nfts": nfts_to_send,
					"fts": fts_to_send,
					"account_to": to,
					"daterange": {
						"datestart": (new Date().getTime() / 1000).toFixed(0),
						"dateexpire": (new Date().getTime() + (1000 * 60 * 60 * 24 * 7) / 1000).toFixed(0),
					},
					"memo": memo
				}
			}
		];
	} else {
		// NORMAL TRADE
		acts = [
			...acts,
			{
				account: 'trade',
				name: 'createprop',
				authorization: [{
					actor: global.wax.userAccount,
					permission: 'active',
				}],
				data: {
					"owner": global.wax.userAccount,
					"nfts": nfts_to_send,
					"fts": fts_to_send,
					"conditions": conds,
					"fees": {
						exchange: global.wax.userAccount,
						exchange_fee: 0,
						affiliate_id: 0,
						affiliate_fee: 0,
					},
					"account_to": to,
					"daterange": {
						"datestart": (new Date().getTime() / 1000).toFixed(0),
						"dateexpire": (new Date().getTime() + (1000 * 60 * 60 * 24 * 7) / 1000).toFixed(0),
					},
					"auto_accept": true,
					memo: memo
				}
			}
		];
	}

	acts = acts.filter(act => act != null)
	return new Promise((resolve, reject) => {
		global.wax.api.transact({
			actions: acts
		}, {
			blocksBehind: 10,
			expireSeconds: 160
		}).then(result => {
			resolve(result)
		}).catch(err => {
			reject(err)
		});

	});
}

export const ftToAssetEx = ft => {
	return {
		"author": TOKEN_CONTRACT,
		"quantity": ft,
		"assettype": 2,
	}
}


export const acceptOffer = async (sendingNfts, sendingFts, tid, box_id, memo = "", requiredFTs = []) => {

	// IDS that are being sent
	let nfts_to_send = sendingNfts.map(nft => nft.id);

	// NFTs that need to be deposited
	let nfts_to_transfer = sendingNfts.filter(nft => !nft.in_wet).map(nft => nft.id)

	let fts_to_send = sendingFts.map(ft => `${ft.amount} ${ft.token}`).map(ftToAssetEx);

	let fts_to_transfer = sendingFts.filter(ft => ft.amount > ft.wet_balance).map(ft => {
		ft.amount -= ft.wet_balance;
		return ft;
	}).map(ft => `${ft.amount} ${ft.token}`);
	let acts = [];
	if (nfts_to_transfer.length > 0 || requiredFTs.length > 0) {
		acts = [
			{
				account: 'trade',
				name: "enabletoken",
				authorization: [{
					actor: global.wax.userAccount,
					permission: 'active',
				}],
				data: {
					owner: global.wax.userAccount,
					nfts: nfts_to_transfer,
					fts: requiredFTs.map(ftToAssetEx),
				},
			}
		]
	}
	if (nfts_to_transfer.length > 0 || fts_to_transfer.length > 0) acts = [
		...acts,
		nfts_to_transfer.length > 0 ? {
			account: 'simpleassets',
			name: "transfer",
			authorization: [{
				actor: global.wax.userAccount,
				permission: 'active',
			}],
			data: {
				from: global.wax.userAccount,
				to: "trade",
				assetids: nfts_to_transfer,
				memo: "",
			},
		} : null,
		...fts_to_transfer.map(ft => ({
			account: TOKEN_CONTRACT,
			name: "transfer",
			authorization: [{
				actor: global.wax.userAccount,
				permission: 'active',
			}],
			data: {
				from: global.wax.userAccount,
				to: "trade",
				quantity: ft,
				memo: "",
			},
		})),

	];

	acts = acts.filter(act => act != null)

	if (sendingNfts.length === 0 && sendingFts.length === 0) {
		acts = [
			...acts,
			{
				account: 'trade',
				name: 'acceptgift',
				authorization: [{
					actor: global.wax.userAccount,
					permission: 'active',
				}],
				data: {
					"owner": global.wax.userAccount,
					"gift_id": tid,
				}
			}
		];
	} else {
		acts = [
			...acts,
			{
				account: 'trade',
				name: 'createoffer',
				authorization: [{
					actor: global.wax.userAccount,
					permission: 'active',
				}],
				data: {
					"owner": global.wax.userAccount,
					"nfts": nfts_to_send,
					"fts": fts_to_send,
					"topropid": tid,
					"box_id": box_id,
					"daterange": {
						"datestart": (new Date().getTime() / 1000).toFixed(0),
						"dateexpire": (new Date().getTime() + (1000 * 60 * 60 * 24 * 7) / 1000).toFixed(0),
					},
					"memo": memo
				}
			}
		];
	}
	acts = acts.filter(act => act != null)
	return new Promise((resolve, reject) => {
		global.wax.api.transact({
			actions: acts
		}, {
			blocksBehind: 10,
			expireSeconds: 160
		}).then(result => {
			resolve(result)
		}).catch(err => {
			reject(err)
		});

	});
}

export const getUnopenedPendingNfts = async (accountName, lower_bound = null, temp = []) => {
	return new Promise((resolve, reject) => {
		global.wax.rpc.get_table_rows({
			json: true,
			code: UNPACKING_CONTRACT,
			scope: accountName,
			table: 'pendingnft.a',
			lower_bound: lower_bound,
			limit: 100,
		}).then(async res => {
			let rows = res.rows;
			let lastLowerBound = 0;
			let merged = [...temp, ...rows];
			if (res.more) {
				lastLowerBound = res.rows[res.rows.length - 1].id + 1;
				return resolve(await getUnopenedPendingNfts(accountName, lastLowerBound, merged))
			}
			merged = merged.filter(row => row.done == 0);
			resolve(merged)
		}).catch(err => {
			reject(err)
		});
	});
}

export const getPacksOwned = (userAccount) => {
	return new Promise(async (resolve, reject) => {
		if (!userAccount) return resolve({ rows: [], more: false });
		global.wax.rpc.get_table_rows({
			json: true,
			code: TOKEN_CONTRACT,
			scope: userAccount,
			table: 'accounts',
			limit: 100,
			reverse: true
		}).then(async ({ rows }) => {
			rows = JSON.parse(JSON.stringify(initialPacks)).map(x => Object.assign(x, rows.find(y => {
				let [xAmount, xToken] = x.balance.split(" ");
				let [yAmount, yToken] = y.balance.split(" ");
				return yToken === xToken
			})));

			let tradeOwned = await getPacksOwnedTrade(userAccount);
			rows = rows.map(tk => {
				let token = tk.balance.split(" ")[1],
					balance = parseFloat(tk.balance.split(" ")),
					wet_balance = 0,
					inproposal = [];
				let validToken = tradeOwned.filter(tk => tk.quantity.split(" ")[1] === token);
				if (validToken && validToken.length > 0) wet_balance = parseFloat(validToken[0].quantity.split(" ")[0]);
				if (validToken && validToken.length > 0) inproposal = parseFloat(validToken[0].inproposal);
				return {
					token,
					balance,
					wet_balance,
					inproposal
				}
			})
			resolve({
				rows: rows,
				more: false
			})
		}).catch(err => {
			reject(err)
		});
	})
}

export const getPacksOwnedTrade = userAccount => {
	return new Promise((resolve, reject) => {
		if (!userAccount) return resolve(null);
		let numericAccountName = numericFromName(userAccount);
		global.wax.rpc.get_table_rows({
			"json": true,
			"code": "trade",
			"scope": "trade",
			"table": "sinventory",
			"table_key": "owner",
			"lower_bound": numericAccountName,
			"upper_bound": BigNumber(numericAccountName).plus(1).toString(),
			"index_position": 3,
			"key_type": "i64",
			"limit": 100,
			"reverse": false,
			"show_payer": false
		}).then(async ({ rows }) => {
			resolve(rows.filter(row => row.assettype === 2
				&& row.author === TOKEN_CONTRACT
			).map(row => ({
				quantity: row.quantity,
				inproposal: row.inproposal
			})))
		}).catch(err => {
			reject(err)
		});
	})
}

export const cancelOffer = (tid) => {
	let acts = [
		{
			account: 'trade',
			name: 'cancelprop',
			authorization: [{
				actor: global.wax.userAccount,
				permission: 'active',
			}],
			data: {
				"owner": global.wax.userAccount,
				"proposal_id": tid,
			}
		},
	];
	return new Promise((resolve, reject) => {
		global.wax.api.transact({
			actions: acts
		}, {
			blocksBehind: 10,
			expireSeconds: 160
		}).then(async result => {
			resolve(result)
		}).catch(err => {
			reject(err)
		});

	});
}

export const withdrawFromTrade = (nfts_to_withdraw = [], fts_to_withdraw = []) => {
	let acts = [
		{
			account: 'trade',
			name: 'withdraw',
			authorization: [{
				actor: global.wax.userAccount,
				permission: 'active',
			}],
			data: {
				"owner": global.wax.userAccount,
				"nfts": nfts_to_withdraw,
				"fts": fts_to_withdraw,
			}
		}
	];
	return new Promise((resolve, reject) => {
		global.wax.api.transact({
			actions: acts
		}, {
			blocksBehind: 10,
			expireSeconds: 160
		}).then(result => {
			resolve(result)
		}).catch(err => {
			reject(err)
		});
	});
}

export const claimNFTS = (nfts_to_withdraw = []) => {
	let acts = [
		{
			account: 'simpleassets',
			name: 'claim',
			authorization: [{
				actor: global.wax.userAccount,
				permission: 'active',
			}],
			data: {
				"claimer": global.wax.userAccount,
				"assetids": nfts_to_withdraw,
			}
		}
	];
	return new Promise((resolve, reject) => {
		global.wax.api.transact({
			actions: acts
		}, {
			blocksBehind: 10,
			expireSeconds: 160
		}).then(result => {
			resolve(result)
		}).catch(err => {
			reject(err)
		});

	});
}

export const getTrade = tid => {
	return new Promise((resolve, reject) => {
		if (!global.wax.userAccount) return reject();
		fetch("https://api.waxnet.io/v1/chain/get_table_rows",
			{
				"body": JSON.stringify({
					"json": true,
					"code": "trade",
					"scope": "trade",
					"table": "stproposals",
					"table_key": "",
					"lower_bound": tid,
					"upper_bound": tid,
					"index_position": 1,
					"key_type": "i64",
					"limit": 1,
					"reverse": false,
					"show_payer": false
				}),
				"method": "POST"
			}).then(res => res.json())
			.then(res => {
				if (res && res.rows && res.rows.length === 1) return resolve(res.rows[0]);
				resolve(null);
			});
	});
}

async function getNftDetails(owner, nftIds) {
	const out = [];
	for (let i = 0; i < nftIds.length; i++) {
		const id = nftIds[i];
		const val = ((await getItem(id, owner)) || (await getItem(id)));
		if (val)
			out.push(val);
	}
	return out;
}

const ITEMS_LOCALSTORAGE_CACHE = 'ITEM_CACHE_v1_';

async function getItem(itemid, owner = 'trade') {
	// const itemKey = `${ITEMS_LOCALSTORAGE_CACHE}_${itemid}`;
	// const cache = window.localStorage.getItem(itemKey);
	// if(cache){
	//   try {
	//       return JSON.parse(cache);
	//   }catch (e) {
	//   }
	// }

	const response = await global.wax.rpc.get_table_rows({
		json: true,
		code: 'simpleassets',
		scope: owner,
		table: 'sassets',
		lower_bound: parseInt(itemid),
		upper_bound: parseInt(itemid),
		limit: 100,
	});

	if (response && response.rows && response.rows.length) {
		const item = response.rows[0];
		item.in_wet = true;
		try {
			item.mdata = JSON.parse(item.mdata)
		} catch (e) {
		}
		try {
			item.idata = JSON.parse(item.idata)
		} catch (err) {
		}
		if (!item.idata) item.idata = {};
		item.mdata = {
			...item.idata,
			...item.mdata
		}

		try {
			//   window.localStorage.setItem(itemKey, JSON.stringify(item));
		} catch (e) {
		}
		return item;
	}
}

async function getTradeInventoryItems(items) {
	if (!items) {
		console.error('getTradeInventoryItems failed: items is undefined')
		return [];
	}
	let results = (await Promise.all(items.map(async x => {
		return getItem(x.aid)
	}))).filter(x => {
		return VALID_AUTHORS[x.author]
	});
	return results;
}

export const getTradeInventory = async (accountName) => {
	if (!accountName)
		throw "accountName not defined";

	let numericAccountName = numericFromName(accountName);
	const result = await fetch("https://api.waxnet.io/v1/chain/get_table_rows",
		{
			"body": JSON.stringify({
				"json": true,
				"code": "trade",
				"scope": "trade",
				"table": "sinventory",
				"table_key": "owner",
				"lower_bound": numericAccountName,
				"upper_bound": BigNumber(numericAccountName).plus(1).toString(),
				"index_position": 3,
				"key_type": "i64",
				"limit": 100,
				"reverse": false,
				"show_payer": false
			}),
			"method": "POST"
		}).then(res => res.json());


	const items = await getTradeInventoryItems(result.rows.filter(row => row.assettype === 0).filter(row => VALID_AUTHORS[row.author] === true));
	return {
		...result,
		rows: items
	}
}

export const getConditionsForTrade = proposalId => {
	return new Promise((resolve, reject) => {
		fetch("https://api.waxnet.io/v1/chain/get_table_rows",
			{
				"body": JSON.stringify({
					"json": true,
					"code": "trade",
					"scope": "trade",
					"table": "sconditions",
					"table_key": "proposalid",
					"lower_bound": proposalId,
					"upper_bound": proposalId,
					"index_position": 2,
					"key_type": "i64",
					"limit": 100,
					"reverse": false,
					"show_payer": false
				}),
				"method": "POST"
			}).then(res => res.json())
			.then(res => {
				resolve(res);
			});
	});
}

export const getCatalog = (series = config.default_series) => {
	return new Promise(resolve => {
		let arr = CatalogHelper.getSeries(series);
		resolve(arr);
	});
}

export const getSentOffers = page => {
	return new Promise((resolve, reject) => {
		if (!global.wax.userAccount) return reject();
		let numericAccountName = numericFromName(global.wax.userAccount);
		fetch("https://api.waxnet.io/v1/chain/get_table_rows",
			{
				"body": JSON.stringify({
					"json": true,
					"code": "trade",
					"scope": "trade",
					"table": "stproposals",
					"table_key": "owner",
					"lower_bound": numericAccountName,
					"upper_bound": BigNumber(numericAccountName).plus(1).toString(),
					"index_position": 2,
					"key_type": "i64",
					"limit": 100,
					"reverse": false,
					"show_payer": false
				}),
				"method": "POST"
			}).then(res => res.json())
			.then(res => {
				resolve(res);
			});
	});
}

export const getPendingOffers = page => {
	return new Promise((resolve, reject) => {
		if (!global.wax.userAccount) return reject();
		let numericAccountName = numericFromName(global.wax.userAccount);
		fetch("https://api.waxnet.io/v1/chain/get_table_rows",
			{
				"body": JSON.stringify({
					"json": true,
					"code": "trade",
					"scope": "trade",
					"table": "stproposals",
					"table_key": "toaccount",
					"lower_bound": numericAccountName,
					"upper_bound": BigNumber(numericAccountName).plus(1).toString(),
					"index_position": 3,
					"key_type": "i64",
					"limit": 100,
					"reverse": false,
					"show_payer": false
				}),
				"method": "POST"
			}).then(res => res.json())
			.then(async res => {
				let validTrades = [];
				let trades = res.rows;
				for (let i = 0; i < trades.length; i++) {
					let trade = trades[i];
					let conds = [];
					// Todo save conditions to trade offer (For caching)
					try {
						conds = (await getConditionsForTrade(trade.id)).rows.filter(cond => cond.boxid === 1).map(c => c.aconditions);
					} catch (err) {
					}
					let valid = true;
					for (let j = 0; j < conds.length; j++) {
						if (!isValidCondition(conds[j])) valid = false;
					}
					for (let j = 0; j < trade.fts.length; j++) {
						let ft = trade.fts[j];
						if (VALID_AUTHORS[ft.author] !== true) valid = false;
					}
					let nfts = await getNftDetails(trade.owner, trade.nfts);
					let validNFts = nfts.filter(nft => VALID_AUTHORS[nft.author] === true);
					if (nfts.length !== validNFts.length) valid = false;

					if (valid) validTrades.push(trade)
				}
				res.rows = validTrades;
				resolve(res);
			});
	});
}

export const conditionsApply = (nft, conditions) => {
	let doApply = true;
	for (let i = 0; i < conditions.length; i++) {
		let cond = conditions[i];
		if (doApply) {
			if (!((cond.field_1 === "=" && nft[cond.field_0] === cond.field_2) || (cond.field_1 === "=" && JSON.stringify(nft[cond.field_0]) === JSON.stringify(cond.field_2)) || (cond.field_1 === "!=" && nft[cond.field_0] !== cond.field_2))) {
				doApply = false;
			}
		}
	}
	return doApply;
}

function isValidCondition(conditions) {
	for (let i = 0; i < conditions.length; i++) {
		const cond = conditions[i];
		if (cond.key === 'author')
			return (cond.operation === '=' && VALID_AUTHORS[cond.value] === true)
	}
	return false;
}
