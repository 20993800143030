import React from 'react';
import ReactDOM from 'react-dom';
import * as waxjs from "@waxio/waxjs/dist";
import App from './App';

import "./config/style.scss";
/* Redux */
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import Reducer from './store/reducer';
import * as CompabilityAPI from './helpers/Compability';
import DevModeHelper from './helpers/DevModeHelper';

const build_version = "v2.0.0.0-live"
let reducerClass = new Reducer();

global.monthNames = ["January", "February", "March", "April", "May", "June",
	"July", "August", "September", "October", "November", "December"
]


if (DevModeHelper.devMode) console.log("[Dev Mode] Enabled")

/* waxjs */
const wax = new waxjs.WaxJS({
	rpcEndpoint: "https://api.waxnet.io",
	tryAutoLogin: false,
});
global.whitelistedContracts = [{ "domain": "toppsgpk.io", "contract": "bhiyveuahwmz", "recipients": [] }, {
	"domain": "toppsgpk.io",
	"contract": "dk2ausoct111",
	"recipients": []
}, { "domain": "toppsgpk.io", "contract": "dk2auwish222", "recipients": [] }, {
	"domain": "toppsgpk.io",
	"contract": "gpk.topps",
	"recipients": []
}, { "domain": "toppsgpk.io", "contract": "oxwxnswsbhzp", "recipients": [] }, {
	"domain": "toppsgpk.io",
	"contract": "packs.topps",
	"recipients": []
}, { "domain": "toppsgpk.io", "contract": "simpleassets", "recipients": [] }, {
	"domain": "toppsgpk.io",
	"contract": "social.topps",
	"recipients": []
}, { "domain": "toppsgpk.io", "contract": "trade", "recipients": [] }, { "domain": "toppsgpk.io", "contract": "wish.topps", "recipients": [] }];
wax.whitelistedContracts = global.whitelistedContracts;

global.wax = wax;

console.log("build_version", build_version);
console.log(CompabilityAPI.getDeviceInformation());

async function start() {
	try {
		let available = await wax.isAutoLoginAvailable();
		if (available) {
			let userAccount = await wax.login();
			reducerClass.updateInitialStore({ userAccount: userAccount });
		}
	} catch (e) {
	}

	const store = createStore(reducerClass.reducer);
	ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
}

start();
