import React, { Component } from 'react'
import Swal from 'sweetalert2'


import "./Inventory.css";

import * as API from "../../helpers/API";
import { Link, Redirect } from 'react-router-dom';

import ShoppingCartIcon from '../../assets/icons/cart.svg';
import ActionCard from '../ActionCard';
import PackView from '../PackView';
import Packs from '../../helpers/packs';
import Loader from '../Loader';
import Popup from '../Popup/Popup';
import DimmingVideo from "./DimmingVideo";
import AuxLayout from '../../helpers/AuxLayout';

import Pagination from '../Pagination';
import PackFilterBar from '../PackFilterBar';
import Reveal from "./Reveal";
import Card from "../Card/Card";

export default class PackInventoryList extends Component {

  _mounted = false;

  state = {
    loaded: false,
    packs: [],
    redirect: undefined,
    unpackConfirmation: false,
    showVideo: false,
    assoc_id: null,

    // Filter bar
    type: "",
    search: "",
    series: "",
    special_filter: ""
  }
  assocId = null;
  videoStarted = 0;


  componentDidMount() {
    this._mounted = true;
    this.loadPacks(this.props.accountName);
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  loadPacks = accountName => {
    if (this._mounted) {
      API.getPacksOwned(accountName).then(({ rows }) => {
        let packs = [];
        let tokens = rows;
        for (let i = 0; i < Packs.length; i++) {
          let pack = Packs[i];
          let token = tokens.filter(tk => tk.token === pack.token);
          console.log(token)
          if (token && token.length > 0) {
            let packToken = token[0];
            let amount = parseInt(packToken.balance) + parseInt(packToken.wet_balance);
            let inTrade = packToken.wet_balance;
            let withdrawRequired = parseInt(packToken.balance) === 0 && parseInt(packToken.wet_balance) > 0
            pack.wd_required = withdrawRequired;
            for (let x = 0; x < amount; x++) {
              pack.wd_item = inTrade > 0;
              packs.push(JSON.parse(JSON.stringify(pack)));
              inTrade--;
            }
          }
        }
        if (this._mounted) {
          this.setState({ loaded: true, packs })
        }
      }).catch(console.error)
    }
  }

  updateFilters = filters => {
    this.setState({
      ...this.state,
      ...filters
    })
  }


  unpack = async pack => {
    if (this._mounted) {
      this.startVideo();
      this.setState({
        showVideo: true
      }, async () => {
        try {
          let { processed } = await API.unbox(global.wax.userAccount, pack);
          if (processed && processed.action_traces && processed.action_traces.length > 0) {
            let at = processed.action_traces;
            for (let i = 0; i < at.length; i++) {
              if (at[i] && at[i].inline_traces && at[i].inline_traces.length > 0) {
                let it = at[i].inline_traces;
                for (let j = 0; j < it.length; j++) {
                  if (it[j].receiver === "orng.wax" && it[j].act && it[j].act.data && it[j].act.data.assoc_id != null) {
                    this.assocId = it[j].act.data.assoc_id;
                    if (this.assocId && this.videoStarted === -1) {
                      this.tryRedirect();
                    }
  
                    setTimeout(() => {
                      if (!this.redirected) {
                        this.redirected = true;
                        return this.setState({ redirect: "/open/" + this.assocId })
                      } else {
                        console.log('already redirected')
                      }
                    }, 1000);
                  }
                }
              }
            }
          }
        } catch (e) {
          console.error(e);
          this.setState({
            showVideo: false,
          })
        }
      })
    }
  }


  startVideo = () => {
    if (this._mounted) {
      this.refs.animationPlayer.play().then(() => {
        this.refs.animationPlayer.pause()
        this.setState({
          showVideo: true
        });
        setTimeout(() => {
          this.refs.animationPlayer.play()
        }, 1000);
      }).catch(console.error)
    }
  }

  tryRedirect() {
    if (this.assocId && this._mounted) {
      if (!this.redirected) {
        this.redirected = true;
        return this.setState({ redirect: "/open/" + this.assocId })
      } else {
        console.log('already redirected in tryRedirect');
      }
    } else {
      setTimeout(() => {
        this.tryRedirect();
      }, 20);
    }
  }

  videoEnded() {
    this.tryRedirect()
  }

  showConfirmation = async (pack) => {
    const result = await Swal.fire({
      title: 'Open Pack?',
      html: `<p>Please confirm that you want to open the <b>${pack.series} - ${pack.edition}</b>. This pack will drop <b>${pack.cardsInPack} cards</b>.</p>`,
      icon: 'question',
      confirmButtonText: 'Open',
      showCancelButton: true,
      CancelButtonText: 'Back'
    });

    if (result.value === true) {
      await this.unpack(pack)
    }
  }

  withdrawPack = async pack => {
    let fts = [{
      author: API.TOKEN_CONTRACT,
      quantity: `1 ${pack.token}`,
      assettype: 2,
    }]
    const result = await Swal.fire({
      title: 'Withdraw Pack?',
      html: `<p>Please confirm that you want to withdraw the <b>${pack.series} - ${pack.edition}</b>. This will cancel all trades it is in</b>.</p>`,
      icon: 'question',
      confirmButtonText: 'Withdraw',
      showCancelButton: true,
      CancelButtonText: 'Back'
    });

    if (result.value === true) {
      await API.withdrawFromTrade([], fts);
      setTimeout(async () => await this.loadPacks(this.props.accountName), 1500)
    }
  }

  render() {
    if (this.state.redirect) return <Redirect to={this.state.redirect} />


    let items = this.state.packs
      .filter(pack => (this.state.type === "" || pack.edition === this.state.type) && (`${pack.series} ${pack.edition}`.toLowerCase().includes(this.state.search.toLowerCase())))
    if (this.state.special_filter === "trade") items = items.filter(pack => pack.wd_item);
    if (this.state.series && this.state.series !== "") items = items.filter(pack => pack.fseries === this.state.series)
    items = items.map((pack, i) => {
      let withdraw = () => this.withdrawPack(pack)
      let open = () => this.showConfirmation(pack)

      let activeAction = null;
      let text = "";
      if (global.wax.userAccount === this.props.accountName) {
        if (this.state.special_filter === "") {
          activeAction = open;
          text = "";
        }
        if (this.state.special_filter === "trade") {
          activeAction = withdraw;
          text = "Withdraw";
        }
      }
       return <PackView key={i} img={pack.img} type={pack.type} withdraw={null} onClick={activeAction} actionText={text} />
    });

    return (
      <AuxLayout>
        <div className={this.state.showVideo ? "unpacking-dimmer" : "unpacking-dimmer-hidden"}>
          <video
            ref="animationPlayer"
            id="dimming-video"
            muted
            playsInline
            onEnded={() => {
              this.videoEnded()
            }}>
            <DimmingVideo />
            Your browser does not support the HTML5 player.
          </video>
        </div>
        <PackFilterBar onChange={this.updateFilters} />
        {
          !this.state.loaded ?
            <Loader />
            :
            <Pagination
              perPage={9}
              items={items}
              wrapper={items => (
                <div id="invview" className={"pack-wrapper"}>
                  {items}
                </div>)
              }
            />
        }
        {this.state.loaded && items.length === 0 && global.wax && this.props.accountName === global.wax.userAccount ?
          (this.state.special_filter == "" && this.state.search == "" ?
            <Link to={"/shop"}><ActionCard className={"full small"} img={ShoppingCartIcon}
                                           text={"Buy Packs"} /></Link> :
            <center><small>No packs found.</small></center>)
          :
          (this.state.loaded && this.state.packs.length === 0 ?
            <center><small><b>{this.props.accountName}</b> doesn't have any packs in their inventory yet.</small>
            </center> : null)}
      </AuxLayout>
    )
  }
}
