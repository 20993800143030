/**
 *
 * Import Media, Classes, etc.
 *
 */

import Logo from "../assets/images/gpk.png";
import Brand from "../assets/icons/topps.svg";
import WAX from "../assets/images/wax-logo-white.png";
import BigBrand from "../assets/images/garbagepailkids.png";
import DevModeHelper from "../helpers/DevModeHelper";

global.devMode = DevModeHelper.devMode;

/**
 *
 * ENUMS
 *
 */

const NFT_STANDARDS = {
	SIMPLE_ASSETS: "sa",
}

// const PAGES = {
//     LANDING_PAGE: true,
//     INVENTORY: true,
//     CATALOG: true,
//     TRADING_INTERFACE: true,
//     COMMUNITY_TAB: true,
//     PROFILE_PAGE: true,
//     FAQ: true
// }

/**
 *
 * CONTRACTS
 *
 */

const style = {
	color: "#eb2e2c",
}

const contracts = {
     UNPACKING: global.devMode ? "bhiyveuahwmz" : "gpk.topps", // gpk.topps
     TOKEN: global.devMode ? "oxwxnswsbhzp" : "packs.topps", // packs.topps
     SOCIAL: "social.topps",
     WISHLIST: "wish.topps",
     ISSUE: global.devMode ? "dk2auissue11" : "issue.wax"
}

/**
 *
 * Tracking Activity
 *
 */

const contracts_to_track = {
	[contracts.SOCIAL]: ["follow", /* "unfollow" */],
	"trade": ["createprop", "acceptprop", "creategift", "requestgift"],
	[contracts.UNPACKING]: ["unbox"],
};


/**
 *
 * Limits, Validation
 *
 */

const validation = {
	AUTHORS: [contracts.UNPACKING, contracts.TOKEN],
}

/**
 *
 * PACK CONFIG
 *
 */

const packs = {
	PACK_TYPES: {
		five: 'five',
		thirty: 'thirty',
		exotic5: 'exotic5',
		exotic25: 'exotic25',
		gpktwoeight: 'gpktwoeight',
		gpktwo25: 'gpktwo25',
		gpktwo55: 'gpktwo55',
	},
	TOKEN_NAMES: {
		five: 'GPKFIVE', // GPKFIVE
		thirty: 'GPKMEGA', // GPKMEGA
		exotic5: 'EXOFIVE', // GPKFIVE
		exotic25: 'EXOMEGA', // GPKMEGA
		gpktwoeight: 'GPKTWOA',
		gpktwo25: 'GPKTWOB',
		gpktwo55: 'GPKTWOC',
	},
}

/**
 *
 * SETTINGS
 *
 */

const settings = {
	NFT_STANDARD: NFT_STANDARDS.SIMPLE_ASSETS,
	CARD_EFFECT: true,
	HAS_PACKS: true
}

/**
 *
 * MEDIA FILES
 *
 */

const media = {
	DOMAIN: "toppsgpk.io",
	LOGO: Logo,
	BRAND: Brand,
	WAX: WAX,
	BIG_LOGO: BigBrand,
}

/**
 *
 * SOCIAL Copy
 *
 */

const social = {
	SELF_SHARE_TEXT: `Check out the digital @ToppsDigital Garbage Pail Kids cards in my @wax_io inventory. Trade offers welcome #GPKWAXPACKS`,
	SHARE_TEXT: `Check out the digital @ToppsDigital Garbage Pail Kids cards in my @wax_io inventory. Trade offers welcome #GPKWAXPACKS`,
}

/**
 *
 * Merge & Export
 *
 */

const config = {
	contracts,
	contracts_to_track,
	packs,
	media,
	settings,
	validation,
	social,
	style,
	default_series: "series2",
};

export default config;