import React, {Component} from 'react';
import AuxLayout from "../../helpers/AuxLayout";

import * as API from '../../helpers/API';

import CloseIcon from '../../assets/icons/close.svg';
import ChainIcon from '../../assets/icons/chain.svg';
import ShareIcon from '../../assets/icons/share.svg';
import FacebookIcon from '../../assets/icons/facebook.svg';
import TwitterIcon from '../../assets/icons/twitter.svg';
import RedditIcon from '../../assets/icons/reddit.svg';

import WithdrawIcon from '../../assets/icons/withdraw.svg';

import Card from '../Card/Card';
import Swal from 'sweetalert2';
import config from '../../config/config';
import replaceIpfsHash from '../../helpers/ipfsDebugger';
class Inspect extends Component {

    state = {
        flipped: false,
        sharing: false
    }

    close = () => {
        this.props.close();
        this.setState({flipped: false, sharing: false})
    }

    render() {
        let own = global.wax && (global.wax.userAccount === this.props.owner || global.wax.userAccount === this.props.trade_owner);
        let notExistant = this.props.specialFilter == "missing";
        if (!this.props.active) return null;
        let backimg = "";
        if (this.props.back) backimg = this.props.back;
        if (this.props.mdata && this.props.mdata.backimg) backimg = this.props.mdata.backimg;
        if (this.props.idata && this.props.idata.backimg) backimg = this.props.idata.backimg;
        if (this.props.backimg) backimg = this.props.mdata.backimg;

        
        console.log(this.props)
        if (this.props.type === "card") return (
            <AuxLayout>
                <div className={"dimmer " + (this.props.active ? "active" : "")} onClick={this.close} />
                <div className={"InspectCard " + (this.props.active ? "active" : "")} style={this.props.style}>
                    <div style={{height: "350px", width: "250px", marginBottom: "25px"}}>
                        <Card src={replaceIpfsHash(this.props.img)} back={replaceIpfsHash(backimg)} portrait={true} no3d={this.props?.mdata?.rarity === "raw" || this.props?.mdata?.variant === "raw"} />
                    </div>
                    <div><center><small style={{color: "#fefefe"}}>Click card to flip</small></center></div>
                    <div className={"actions"}>
                        {
                            !notExistant ?
                            <div className={"share"} tooltip="Showcase Cards" tooltip-position="top" onClick={() => this.setState({sharing: !this.state.sharing})}>
                                <img src={ShareIcon} alt={"Showcase Cards"} />
                            </div>
                            :
                            <div></div>
                        }
                        {
                            !notExistant ?
                            <div className={"chain"} tooltip="View on the WAX Blockchain" tooltip-position="top" onClick={() => window.open(`https://wax.bloks.io/nft/simpleassets/${this.props.owner}/sassets/${this.props.id}`, '_blank')}>
                                <img src={ChainIcon} alt={"View on the WAX Blockchain"} />
                            </div>
                            : <div></div>
                        }
                        {
                            !notExistant && this.props.in_wet && own ?
                            <div className={"withdraw"} tooltip="Withdraw from Trading" tooltip-position="top" onClick={this.props.withdraw}>
                                <img src={WithdrawIcon} alt={"Withdraw from Trading"} />
                            </div>
                            : <div></div>
                        }
                        <div className={"close"} tooltip="Close" tooltip-position="top" onClick={this.close}>
                            <img src={CloseIcon} alt={"Close Inspect"} />
                        </div>
                        {
                            this.state.sharing ?
                                <AuxLayout>
                                    <hr />
                                    <a target="_blank" href={`https://twitter.com/intent/tweet?url=https://toppsgpk.io/p/${global.wax.userAccount }&text=${ encodeURIComponent(own ? config.social.SELF_SHARE_TEXT : config.social.SHARE_TEXT) }&via=ToppsDigital&related=Topps,GPK,WAX,WAX_io`} className={"share"} tooltip="Twitter" tooltip-position="top">
                                        <img src={TwitterIcon} alt={"Twitter"} />
                                    </a>
                                    <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=https://toppsgpk.io/p/${global.wax.userAccount}`} className={"share"} tooltip="Facebook" tooltip-position="top">
                                        <img src={FacebookIcon} alt={"Facebook"} />
                                    </a>
                                    <a target="_blank" href={`https://www.reddit.com/submit?url=https://toppsgpk.io/p/${global.wax.userAccount}&title=${encodeURIComponent(own ? config.social.SELF_SHARE_TEXT : config.social.SHARE_TEXT)}`} className={"share"} tooltip="Reddit" tooltip-position="top">
                                        <img src={RedditIcon} alt={"Reddit"} />
                                    </a>
                                </AuxLayout>
                            : null
                        }
                    </div>
                </div>
            </ AuxLayout >
        );
        return null
    }
}

export default Inspect;