import React, { Component } from 'react'

import "./Inventory.css";

import * as API from "../../helpers/API";

import WishlistAPI from "../../helpers/contracts/wishlist";
import ActionCard from '../ActionCard';

import ItemView from '../ItemView';

import CatalogIcon from '../../assets/icons/catalog.svg';
import Popup from '../Popup/Popup';

import Pagination from '../Pagination';
import Loader from '../Loader';

export default class WishlistView extends Component {

    _mounted = false;

    state = {items: [], wishlist: [], addingCards: false, mode: 1}

    componentDidMount () {
        this._mounted = true;
        this.loadCatalog();
    }

    componentWillUnmount () {
        this._mounted = false;
    }

    loadCatalog = () => {
        if (!this._mounted) return;
        API.getCatalog().then(list => {
            if (!this._mounted) return;
            this.setState({ items: list })
            if (!this._mounted) return;
            this.loadWishlist();
        }).catch(console.error)
    }

    loadWishlist = async () => {
        if (!this._mounted) return;
        WishlistAPI.getWishlist(this.props.accountName).then(list => {
            if (!this._mounted) return;
            this.setState({ wishlist: list.rows, loading: false })
        }).catch(err => {
            console.error(err)
            if (!this._mounted) return;
            this.setState({ loading: false })
        })
    }

    addToWishlist = async (name, rarity) => {
        if (!this._mounted) return;
        this.setState({loading: true, mode: 1})
        if (!this._mounted) return;
        await WishlistAPI.addToWishlist(name, rarity);
        await new Promise(r => setTimeout(() => r(), 1200))
        if (!this._mounted) return;
        this.loadWishlist();
    }
    removeFromWishlist = async id => {
        if (!this._mounted) return;
        this.setState({loading: true, mode: -1})
        if (!this._mounted) return;
        await WishlistAPI.removeFromWishlist(id);
        await new Promise(r => setTimeout(() => r(), 1200))
        if (!this._mounted) return;
        this.loadWishlist();
    }

    render() {
        let items = this.state.items.filter(item => {
            let wish = this.state.wishlist.filter(wish => wish.wish === item.name && item.rarity.toLowerCase() === wish.rarity.toLowerCase());
            if (wish && wish.length > 0) {
                item.wishId = wish[0].id;
                return true;
            }
            return false;
        });
        if (items.length === 0 && global.wax && this.props.accountName === global.wax.userAccount && !this.state.addingCards) return <ActionCard className={"full small"} img={CatalogIcon} text={"Add cards to wishlist"} onClick={() => {
            if (!this._mounted) return;
            this.setState({addingCards: true})
        }} />;
        if (items.length === 0  && this.props.accountName !== global.wax.userAccount ) return <center><small>{ this.props.accountName } doesn't have any cards wishlist yet.</small></center>;
        items = items.map((item, i) => (
            <ItemView key={i} name={item.name} image={item.images.front} removalAction={this.props.accountName === global.wax.userAccount ? () => this.removeFromWishlist(item.wishId) : null} />
        ));
        return (
            <div id="invview" className={""}>
                
                <Pagination 
                perPage={8}
                    wrapper={items => <div style={{overflow: "auto", display: "flex", justifyContent: "space-between"}}>
                        { items }
                        {this.props.accountName === global.wax.userAccount ? <ActionCard key={-145135315} className={"small"} img={CatalogIcon}  text={"Add more"} onClick={() => this.setState({addingCards: true})} /> : null}
                    </div>} 
                    items={items} />
                <Popup active={this.state.addingCards} close={() => this.setState({addingCards: false})}>
        <div className={"title"} style={{marginTop: 0, fontSize: "1.5rem", color: "#323232"}}>{ !this.state.loading ? "Click on card to add to Wishlist" : (this.state.mode == 1 ? "Adding card to wishlist..." : (this.state.mode == -1 ? "Removing card from wishlist..." : "Loading...")) }</div>
                    
                    {
                        this.state.loading ?
                        <Loader />
                        : 
                        <Pagination 
                        perPage={10}
                         wrapper={items => <div className={"wishlist-catalog"}>{items}</div>} 
                         items={this.state.items.map((item, i) => {
                             let onWishlist = this.state.wishlist.filter(wish => wish.wish === item.name).length > 0;
                                return <ItemView 
                                    key={i} 
                                    name={item.name} 
                                    image={item.images.front}
                                    className={onWishlist ? "selected" : ""}
                                    onClick={!onWishlist ? this.addToWishlist.bind(this, item.name, item.rarity) : (() => this.removeFromWishlist(item.wishId))} 
                                />
                            })}/>
                    }

                </Popup>
            </div>
        )
    }
}
