import React, { Component } from 'react'

import AuxLayout from '../helpers/AuxLayout';
import PackFilterBar from './PackFilterBar';

export default class Pagination extends Component {

    constructor (props) {
        super(props)
        this.state = {
            currentPage: this.props.page ? parseInt(this.props.page ) : 1,
        }
        this.prevItems = props.items.length;
    }

    componentDidUpdate () {
        if (this.prevItems != this.props.items.length) {
            this.prevItems = this.props.items.length;
            this.setState({ currentPage: 1 })
            return;
        }
    }

    changePage = () => {

    }
    
    render() {
        let { items, perPage=10 } = this.props;
        let maxPage = Math.ceil(items.length / perPage);
        let lb = (this.state.currentPage-1) * perPage;

        let prevPages = this.state.currentPage > 3 ? 3 : this.state.currentPage-1;
        let pagination = [];
        for (let i = 0; i<prevPages; i++) {
            let nr = prevPages - i;
            let specNr = this.state.currentPage - nr;
            pagination.push(<span key={i} className={"pager"} onClick={() => this.setState({currentPage: specNr})}>{specNr}</span>);
        }
        pagination.push(<span key={-1} className={"pager active"}>{this.state.currentPage}</span>);
        let pagesLeft = maxPage - this.state.currentPage;
        let nextPages = 3;
        if (pagesLeft > 6 - prevPages) nextPages = 6 - prevPages;
        if (pagesLeft <= 3) nextPages = pagesLeft;

        for (let i = 0; i<nextPages; i++) {
            let nr = i+1;
            let specNr = this.state.currentPage + nr;
            pagination.push(<span key={i + prevPages} className={"pager"} onClick={() => this.setState({currentPage: specNr})}>{specNr}</span>);
        }

        return (
            <AuxLayout>
                {
                    this.props.wrapper ?
                    this.props.wrapper(items.slice(lb, lb+perPage))
                    : 
                    items.slice(lb, lb+perPage)
                }
                {
                    maxPage > 1 ?
                        <div className={"Pagination box"}>
                            <span className={"pager"} onClick={() => this.setState({currentPage: 1})}>«</span>
                            <span className={"pager"} onClick={this.state.currentPage > 1 ? () => this.setState({ currentPage: this.state.currentPage - 1 }) : null}>Prev Page</span>
                            
                            { pagination }
        
                            <span className={"pager"} onClick={this.state.currentPage < maxPage ? () => this.setState({ currentPage: this.state.currentPage + 1 }) : null}>Next Page</span>
                            <span className={"pager"} onClick={() => this.setState({currentPage: maxPage})}>»</span>
                        </div>
                    : null
                }
            </AuxLayout>
        )
    }
}
