import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Pack from '../components/Pack/Pack'

export default class CardView extends Component {

    render() {
        let { name, img , type} = this.props;
        return (
            <div className={"PackView" + (this.props.onClick ? " clickable": "")} onClick={this.props.onClick} title={this.props.onClick ? (this.props.actionText ? this.props.actionText : "Open Pack") : ""}>
                <Pack type={type} src={img.includes("http") ? img : ("https://cloudflare-ipfs.com/ipfs/" + img) } className={"preview"}/>
            </div>
        )
    }
}