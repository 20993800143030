import React, { Component } from 'react'

import './Social.css';


import * as SocialAPI from "../../helpers/contracts/social";
import { Link } from 'react-router-dom';

export default class FollowerList extends Component {
    _mounted = false;


    state = {followers: [], loaded: false};

    componentDidMount () {
        this._mounted = true
        SocialAPI.getFollowers(this.props.accountName).then(data => {
            if (!this._mounted) return;
            this.setState({followers: data.rows, loaded: true})
        });
    }

    componentWillUnmount () {
        this._mounted = false
    }

    render() {
        if (this.state.loaded && this.state.followers.length === 0) return <small className="nofollowing">Currently no one follows { this.props.accountName }</small>;
        return <div className={"friends"}>
            {
                this.state.followers.map(friendship => (
                    <Link key={this.state.followers.indexOf(friendship)} to={this.props.link ? this.props.link.replace(":uid", friendship.follower) : ("/p/" + friendship.follower)} className={"box friend"}>
                            <b className="name">{ friendship.follower }</b><br/>
                            <small className="action">followed for X months</small>
                    </Link>
                ))
            }
        </div>
        }
}
