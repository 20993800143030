import React, { Component } from "react";

import video from "../../assets/videos/series_2_small.mp4";

function renderMp4(){
  return (
    <source src={video} type="video/mp4" id="video" />
  )
}

export default class UnpackVideo2 extends Component {
  render() {
    return [
      renderMp4()
    ]
  }
}
