import React, {Component} from 'react';
import {connect} from "react-redux";

import * as SocialAPI from "../../helpers/contracts/social";
import * as ActivityAPI from '../../helpers/activity';

import "./SelectTradePartner.css";
import FollowedList from '../../components/Social/FollowedList';
import Loader from '../../components/Loader';
import AuxLayout from '../../helpers/AuxLayout';

import { Link } from 'react-router-dom';
import config from '../../config/config';

class SelectTradePartner extends Component {

    _mounted = false;

    state = { 
        step: 0, 
        following: [],
        activeUsers: [],
        activeUsersLoading: true
    };

    componentDidMount () {
        this._mounted = true;
        this.loadFollowing(this.props.userAccount);
        this.loadActivity();
    }

    componentWillUnmount () {
        this._mounted = false;
    }

    loadFollowing = accountName => {
        if (!this._mounted) return;
        SocialAPI.getFollowing(accountName).then(data => {
            if (!this._mounted) return;
            this.setState({following: data.rows});
            if (data.more) {
                console.log("more friends found...");
            }
        }).catch(console.error)
    }

    loadActivity = () => {
        if (!this._mounted) return;
        ActivityAPI.getCommunityActions().then(contractResults => {
            let activeAccounts=[]
            // Recently Active Users
            for (let i = 0; i<contractResults.length; i++) {
                let contractData = contractResults[i];
                for (let j = 0; j<contractData.length; j++) {
                    let tx = contractData[j];
                    activeAccounts = [
                        ...activeAccounts,
                        ...tx.auth
                    ];
                }
            }
            let activeUsers = Array.from(new Set(activeAccounts))
            if (!this._mounted) return;
            this.setState({activeUsers: activeUsers, activeUsersLoading: false})
        }).catch(console.error)
    }

    render() {
        return (
            <div className="container" id="createtrade">
                {/* <div className="actions">
                    <button>Create trade</button>
                </div> */}
                <div>
                    <div className="info">
                        Trade with someone you <b>follow</b>
                    </div>
                    <div className="friends box" style={{display: "flex", overflowY: "auto"}}>
                        <FollowedList easyFix={true} accountName={this.props.userAccount} link={"/p/:uid/trade/" + this.props.location.search}></FollowedList>
                    </div>
                    {
                        this.state.activeUsersLoading ?
                            <Loader></Loader>
                        :
                        <AuxLayout>
                            <div className="info">
                                or recently active <b>community members</b>
                            </div>
                            <div className="friends">
                                { this.state.activeUsers.map((user, i) => (
                                    <Link key={i} to={`/p/${user}/trade`} className={"box friend"}>
                                        <img src={config.media.LOGO} alt="" className="avatar" />
                                        <b className="name">{ user }</b><br/>
                                    </Link>
                                )) }
                            </div>
                        </AuxLayout>
                    }
                </div>
            </div>
        );
    }
}
const mapStateToProps  = state => {
    return {
        userAccount: state.userAccount
    }
}

export default connect(mapStateToProps)(SelectTradePartner);