import * as API from './API';
import config from '../config/config';

const contracts = config.contracts_to_track;

export const getRecentActions = (accountName, customContracts=({})) => {
    return new Promise((resolve, reject) => {
        if (!accountName) return reject("No name defined");
        fetch("https://wax.greymass.com/v1/history/get_actions",
            {
                "body":JSON.stringify({
                    account_name: accountName,
                    offset: -100,
                    pos: -1
                }),
                "method":"POST"}).then(res => res.json())
            .then(({ actions }) => {
                actions = actions
                .map(action => {
                    let { account, data, name, authorization } = action.action_trace.act;
                    let time = new Date(action.block_time);
                    let auth = authorization.map(auth => auth.actor);
                    let tx = action.action_trace.trx_id;
                    return { tx, account, data, name, time, auth }
                })
                .filter(action => {
                    if (JSON.stringify(customContracts) !== JSON.stringify({})) return customContracts[action.account] != null && customContracts[action.account].includes(action.name)
                    else return contracts[action.account] != null && contracts[action.account].includes(action.name)
                })
                .sort((a, b) => b.time.getTime() - a.time.getTime());
                resolve(actions);
            }).catch(console.error)
    });
}

export const getCommunityActions = () => {
    return new Promise(async (resolve, reject) => {
        let cs = Object.keys(contracts);
        // let activeAccounts = []

        const promises = [];
        for (let i = 0; i<cs.length; i++) {
            promises.push(getRecentActions(cs[i]));
        }
        let contractResults = await Promise.all(promises);
        resolve(contractResults);
    });
}

export const getRecentlyOpenedCards = () => {
    return new Promise((resolve, reject) => {
        fetch(`https://api.waxsweden.org/v2/history/get_actions?filter=simpleassets:create&account=${config.contracts.UNPACKING}&limit=10`)
        .then(res => res.json())
        .then(({actions}) => {
            let unpackings = actions.map(actions => actions.act);
            unpackings = unpackings.map(tx => tx.data).map(nft => {
                try {nft.mdata = JSON.parse(nft.mdata)} catch(e) {}
                try {nft.idata = JSON.parse(nft.idata)} catch (err) {}
                if (!nft.idata) nft.idata = {};
                nft.mdata = {
                    ...nft.idata,
                    ...nft.mdata
                }
                return nft;
            })
            resolve(unpackings)
        }).catch(console.error)
    });
}
