import React, { Component } from 'react'

import "./Payment.css";
import { withRouter, Link, Redirect } from 'react-router-dom';
import AuxLayout from '../../helpers/AuxLayout';

import SuccessIcon from '../../assets/icons/success.svg';
import PendingIcon from '../../assets/icons/pending.svg';
import PendingSuccessIcon from '../../assets/icons/pending_success.svg';
import FailedIcon from '../../assets/icons/failed.svg';
import StripeHandler from '../../helpers/StripeHandler';
import ShopHelper from '../../helpers/lib/ShopHelper';
import Packs from '../../helpers/packs';
import PaymentAPI from '../../helpers/lib/PaymentAPI';
import DevModeHelper from '../../helpers/DevModeHelper';

const stripeLoader = StripeHandler.getNewStripeInstance();

global.devMode = DevModeHelper.devMode

class Payment extends Component {

    _mounted = false;

    constructor (props) {
        super(props);
        let id = props.match.params.tx;
        let paymentIntent = localStorage.getItem(`pi_${id}`);
        if (id && paymentIntent) this.state = JSON.parse(paymentIntent);
        else {
            let currentId = localStorage.getItem(`current_pi`);
            let current_intent = localStorage.getItem(currentId);
            console.log(currentId, current_intent)
            if (current_intent) {
                try {current_intent = JSON.parse(current_intent);} catch (err) {}
                this.state = {redirect: '/payment/' + current_intent.id.substr(3)}
            } else {
                this.state = {redirect: '/shop'}
            }
        }
    }

    componentDidMount () {
        this._mounted = true;
        this.checkStatus();
        this._interval = setInterval(() => {
            if (this.state.status === "canceled" || (this.state.status == "succeeded" && this.state.metdata && this.state.metdata.issued)) return clearInterval(this._interval)
            if (this._mounted) {
                this.checkStatus();
            } else {
                return clearInterval(this._interval)
            }
        }, 5000)
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    checkStatus = async () => {
        fetch(`https://pay.wax.io/v1${global.devMode ? "test" : ""}/intent/get?id=${this.state.id}&client_secret=${this.state.client_secret}`)
        .then(res => res.json())
        .then(res => {
            if (res && res.id == this.state.id) {
                localStorage.setItem(`pi_${res.id}`, res);
                this.setState(res);
            } else {
                this.setState({error: "An error occurd"});
            }
        }).catch(err => {
            this.setState({error: "An error occurd", info: err});
        })
    }

    render() {
        if (this.state.redirect) return <Redirect to={this.state.redirect} />
        if (this.state.error) return <center className="container">{ this.state.error }</center>
        if (!this.state.metadata) return <span>No Meta Data</span>
        let { accountName, pack, amount, issued } = (this.state.metadata);
        console.log(this.state.metadata)
        let product = ShopHelper.getProductByToken(pack);
        return (
            <div className="container" id="payment">
                <div className={"box"} id="summary">
                    <h1>Order Summary</h1>
                    <h2>#{ this.props.match.params.tx }</h2>
                    <div id="summary">
                        <div>Recipient: <span className="right">{ accountName }</span></div>
                        <hr />
                            <AuxLayout>
                                <div>Product: <span className="right">{ product.name }</span></div>
                                <div>Edition: <span className="right">{ product.edition } ({ product.cardCount } Cards)</span></div>
                                <div>Quantity: <span className="right">{amount}x ${ (product.price / 100).toFixed(2)   }</span></div>
                            </AuxLayout>
                        <hr />
                        <div>Payment Method: <span className="right provider">{ this.state.payment_method_types[0] }</span></div>
                        <hr />
                        <div>Total: <span className="right">${ (this.state.amount / 100).toFixed(2)   }</span></div>
                    </div>
                </div>
                <div className={"box"} id="statusbox">
                    {
                        this.state.status === "canceled" ?
                        <AuxLayout>
                            <img src={FailedIcon} alt={""} />
                            <h1>Order Cancelled</h1>
                            <p>We're sorry to inform you that your order got canceled.</p>
                        </AuxLayout>
                        : null
                    }
                    {
                        this.state.status === "requires_capture" || this.state.status === "requires_payment_method"?
                        <AuxLayout>
                            <img src={PendingIcon} alt={""} />
                            <h1>Order Received</h1>
                            <p>We've received your order and are processing your payment. This may take a few moments.</p>
                        </AuxLayout>
                        : null
                    }
                    {
                        this.state.status === "succeeded" && !issued?
                        <AuxLayout>
                            <img src={PendingSuccessIcon} alt={""} />
                            <h1>Payment Successful!</h1>
                    <p>We've received your payment and are sending your digital pack{ this.state.quantity !== 1 ? "s" : "" }! This may take a few moments for your pack{ this.state.quantity !== 1 ? "s" : "" } to appear in your <Link to={"/inventory"}>inventory</Link>.</p>
                        </AuxLayout>
                        : null
                    }
                    
                    {
                        this.state.status === "succeeded" && issued ?
                        <AuxLayout>
                            <img src={SuccessIcon} alt={""} />
                            <h1>Congratulations!</h1>
                            <p>Hurray! You've received your pack{ this.state.quantity !== 1 ? "s" : "" }. Visit your inventory now to view, open, and trade them. Pretty cool, huh?</p>
                            <Link to={"/inventory"}><button>Go to Inventory</button></Link>
                            <p><small>Transaction: <a href={"https://wax.bloks.io/transaction/" + issued} target="_blank">{ issued.substr(0, 6) }</a></small></p>
                        </AuxLayout>
                        : null
                    }
                    
                </div>
            </div>
        )
    }
}

export default withRouter(Payment)