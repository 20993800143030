import React, {Component} from 'react';

import ItemView from '../ItemView';
import Pagination from '../Pagination';

class CardSelector extends Component {
    render() {
        let items = [];
        
        if (this.props.extra) items.push(this.props.extra)
        items = 
        [
            ...items,
            ...this.props.items.filter(item => item.mdata != null).map((item, i) => {
                let name, image;
                try {item.mdata = JSON.parse(item.mdata)} catch (err) {}
                try {item.idata = JSON.parse(item.idata)} catch (err) {}
                if (!item.idata) item.idata = {};
                item.mdata = {
                    ...item.idata,
                    ...item.mdata
                }
                if (item.mdata.name) name = item.mdata.name;
                if (item.mdata.img) image = item.mdata.img;
                if (item.mdata.image) image = item.mdata.image;
                if (image && !image.includes("http")) image = "https://cloudflare-ipfs.com/ipfs/" + image;
                return (
                    <ItemView key={i} className={this.props.selected && this.props.selected.includes(item) ? "selected " : ""} name={name} image={image} onClick={this.props.toggleSelection ? () => this.props.toggleSelection(item) : null} removalAction={this.props.removalAction ? () => this.props.removalAction(item) : null} />
                )
            })
        ]
        return this.props.noPagination ? items : <Pagination wrapper={items => <div className="items">{items}</div>} items={items} />;
    }
}

export default CardSelector;