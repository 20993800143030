import React, { Component } from 'react'

export default class Loader extends Component {
    render() {
        return (
            <div className="Loader">
                <svg width="80" height="50" viewBox="0 0 80 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="20" height="50" fill="#EB2E2C"/>
                    <rect x="30" width="20" height="50" fill="black"/>
                    <rect x="60" width="20" height="50" fill="#EB2E2C"/>
                </svg>
            </div>
        )
    }
}
