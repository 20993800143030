import React, { Component } from 'react';
import * as actionTypes from "../../store/actionTypes";

import { connect } from "react-redux";

import "./Shop.css";
import { Link } from "react-router-dom";
import Loader from '../../components/Loader';

import collectablesIcon from '../../assets/communitymarkets/collectables.io.png';
import simplemarketIcon from '../../assets/communitymarkets/simplemarket.png';
import gpkMarketIcon from '../../assets/communitymarkets/gpk.market.png';
import waxplorerIcon from '../../assets/communitymarkets/waxplorer.jpg';

import Pack from '../../components/Pack/Pack';
import ShopHelper from '../../helpers/lib/ShopHelper';
import PurchaseForm from '../../components/Shop/PurchaseForm';
import StripeHandler from '../../helpers/StripeHandler';

import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import Swal from 'sweetalert2';
import { contracts } from '../../helpers/API';
import config from '../../config/config';

class Shop extends Component {

  _mounted = false;

  state = {
    loaded: false,
    packs: [],
    purchase: null,
    requireLogin: false,
    quantity: {},
    tokenSupply: {},
  }


	communityMarkets = [
		{
			search: 'https://gpk.market/packs/',
			icon: gpkMarketIcon,
			name: "GPK.Market"
		},
		{
			search: 'https://collectables.io/?author=packs.topps&orderby_block=desc&tab=All%20Listings',
			icon: collectablesIcon,
			name: "Collectables.io"
		},
		{
			search: 'https://waxplorer.com/?tab=pack-offers&author=gpk.topps',
			icon: waxplorerIcon,
			name: "WAXplorer"
		}
  ]

  componentDidMount() {
    this._mounted = true;
    ShopHelper.getProducts().then(data => {
      let quantity = {};
      for (let i = 0; i < data.length; i++) {
        if (data[i].price < 500) {
          quantity[data[i].slug] = 2;
        } else {
          quantity[data[i].slug] = 1;
        }
      }
      if (!this._mounted) return;
      this.updateSupply().then(res => {
        this.setState({ loaded: true, packs: data, quantity })
      });
      setTimeout(this.refreshSupply, 3000);
    }).catch(console.error)
  }

  refreshSupply = () => {
    if (!this._mounted) return;
    this.updateSupply().then(res => {
      setTimeout(this.refreshSupply, 3000);
    })
  }

  updateSupply = () => {
    return new Promise(resolve => {
      this.loadSupply().then(rows => {
        let tokenSupply = {};
        for (let row of rows) {
          let [amt, token] = row.balance.split(" ");
          tokenSupply[token] = amt;
        }
        this.setState({ tokenSupply }, () => {
          resolve()
        })
      });
    })
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  login = async () => {
    await Swal.showValidationMessage("Test")
    let userAccount = await global.wax.login();
    if (userAccount) {
      this.props.onAuthUpdate(userAccount);
    }
    global.wax.whitelistedContracts = global.whitelistedContracts;
  }

  purchase = async (product) => {
    this.setState({purchase: product})
  }

  loadSupply = () => {
    return new Promise(resolve => {
      fetch("https://api.waxnet.io/v1/chain/get_table_rows",
        {
          "body": JSON.stringify({
            "json": true,
            "code": contracts.TOKEN,
            "scope": contracts.ISSUE,
            "table": "accounts",
            "key_type": "i64",
            "limit": 100,
            "reverse": false,
            "show_payer": false
          }),
          "method": "POST"
        }).then(res => res.json())
        .then(res => {

          // load reserved
          fetch("https://api.waxnet.io/v1/chain/get_table_rows",
          {
            "body": JSON.stringify({
              "json": true,
              "code": contracts.ISSUE,
              "scope": contracts.TOKEN,
              "table": "balances",
              "key_type": "i64",
              "limit": 100,
              "reverse": false,
              "show_payer": false
            }),
            "method": "POST"
          }).then(res => res.json())
          .then(reservedRows => {
            try {
              resolve(res.rows.map(row => {
                let [amnt, token] = row.balance.split(" ");
                amnt = parseFloat(amnt);

                let reservation = reservedRows.rows.filter(r => {
                  let [a, t] = r.reserved.split(" ");
                  return t == token;
                })
                if (reservation.length > 0) {
                  let [amntReserved] = reservation[0].reserved.split(" ")
                  amnt -= parseFloat(amntReserved);
                }
                if (amnt < 0) amnt = 0;
                row.balance = `${amnt} ${token}`;
                return row;
              }));
            } catch (err) {
              try {
                resolve(res.rows);
              } catch (err) {}
            }
          })
        });
    })
  }

  purchase = pack => {
    this.setState({purchase: pack})
  }

  modifyQuantity = (slug, modifier, e) => {
    let { quantity } = this.state;
    quantity[slug] += modifier;
    if (quantity[slug] < 1) quantity[slug] = 1;
    if (!this._mounted) return;
    this.setState({ quantity })
  }

  formatNumber = (amount, decimalCount = 0, decimal = ".", thousands = ",") => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      console.log(e)
    }
  };

  render() {
    let {quantity} = this.state;
    if (this.state.purchase) return (
        <div className="container" id="shop">
            <h1>Purchasing: {this.state.purchase.name} - { this.state.purchase.edition }</h1>
            <div className={"box"} id="purchaseformbox">
                <PurchaseForm onAuthUpdate={this.props.onAuthUpdate} stripe={this.props.stripe} userAccount={this.props.userAccount} product={this.state.purchase} quantity={this.state.quantity[this.state.purchase.slug]} />
            </div>
                        <div className="purchaseFormRequired">
                            <small style={{ fontSize: "14px" }}>* requried fields</small>
                        </div>
        </div>
    )
    return (
      <div className="container" id="shop">
        <h1>Buy a GPK Card Pack</h1>
        {/* <h4 className={"subtitle"}>After you purchase your card pack, you can open up the pack to get your cards</h4> */}
				<div className="markets">
					<b>Missed the pack sale? Don't worry! You can still get packs & cards on secondary markets:</b>

					<div>
						{
							this.communityMarkets.map(x => {
								return (
									<a href={x.search}
										target={"_blank"}
										key={x.name}
										rel={"noopener noreferer"}
										tooltip={x.name}
										tooltip-position={"bottom"}
									>
										<div className={"market-button"}
											style={{ backgroundImage: `url(${x.icon})`, }} />
									</a>
								)
							})
						}
					</div>
				</div>
        {
          this.state.loaded ?
            this.state.packs.map((pack, i) => {
              if (this.state.tokenSupply && this.state.tokenSupply[pack.token]) {
                pack.supply = this.state.tokenSupply[pack.token];
              } else {
                pack.supply = 0
              }
              return (
                <div key={i} className="pack-wrapper">
                  <h2>{pack.edition} <small>(${(pack.price / 100).toFixed(2)})</small></h2>
                  <div className="pack">
                    {pack.supply <= 0 ? <div className={"sold-out-text"}>SOLD OUT</div> : null}
                    <div className={"info" + (pack.supply <= 0 ? " sold-out" : "")}>
                      {pack.supply > 0 ? <div className={"pack-counter"}>{(pack.supply)} remaining</div> : null}
                      <div className={"pack-image-wrapper"}>
                        <div className={"pack-image"}>
                          <Pack src={"https://cloudflare-ipfs.com/ipfs/" + pack.image} />
                        </div>
                      </div>
                      <div className="details">
                        <div className="more-info">
                          <div className="tag"><b className={"key"}>Pack:</b> {pack.edition}</div>
                          <div className="tag"><b className={"key"}>Series:</b> {pack.name}</div>
                          <div className="tag"><b className={"key"}>Release Date:</b> {pack.date}</div>
                          <div className="tag"><b className={"key"}>Contains:</b> {pack.cardCount} Cards</div>
                        </div>
                        {/* <h6 className="cards"><small><b style={{fontSize: "1.3rem"}}>{ pack.cardCount } Cards</b> <i>Digital Pack</i></small></h6> */}
                        <p className="description">
                          <b>Includes:</b>
                          {
                            pack.content.map((item, i) => {
                              return <li key={i}>{item}<br /></li>;
                            })
                          }
                        </p>
                      </div>
                      {
                        pack.supply > 0 ?
                          <div className="form">
                            <button className={"buy"} onClick={() => this.props.userAccount ? this.purchase(pack) : this.login()}>{this.props.userAccount ? "Buy now" : "Sign in "}</button>
                            <Link to={"/catalog"}>
                              <button className={"catalog gray"}>View Catalog</button>
                            </Link>
                          </div>
                          : null
                      }
                    </div>
                  </div>
                </div>
              );
            })
            :
            (window.innerWidth > 1600 ? [1, 2] : [1]).map(i => <div key={i} className="pack-wrapper"><Loader /></div>)
        }
      </div>
    );
  }
}


const InjectedShop = (prop) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <Shop stripe={stripe} elements={elements}  {...prop} />
    )}
  </ElementsConsumer>
);

const stripePromise = StripeHandler.getNewStripeInstance();
global.stripe = stripePromise;
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const ShopRenderer = (prop) => {
  return (
    <Elements stripe={stripePromise}>
      <InjectedShop {...prop} />
    </Elements>
  );
};


const mapStateToProps = state => {
  return {
    userAccount: state.userAccount
  }
}


const mapDispatchToProps = dispatch => {
  return {
    onAuthUpdate: userAccount => dispatch({ type: actionTypes.UPDATE_AUTH_USERACCOUNT, userAccount: userAccount })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopRenderer);
