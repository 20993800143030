import React, {Component} from 'react';

import "./Catalog.css";

import * as API from "../../helpers/API";
import FilterBar from '../../components/FilterBar';

import Card from '../../components/Card/Card';
import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination';
import config from '../../config/config';
import replaceIpfsHash from '../../helpers/ipfsDebugger';

class Catalog extends Component {
    _mounted = false;
    state = {
        loaded: false,
        catalog: [],
        // Filter
        series: config.default_series,
        rarity: "",
        namevariant: "",
        search: "",
        only_wet: false,

        mobile: window.innerWidth < 400,
        cardHeight: 350,
        cardWidth: 250
    };

    componentDidMount () {
        this._mounted = true;
        this.loadCatalog(this.state.series);
        this.resizeListener = window.addEventListener("resize", e => {

            if (this._mounted && this.state.mobile && window.innerWidth < 400) {
                if (this._mounted) {
                    this.setState({mobile: window.innerWidth < 400});
                }
            }
        })
    }

    checkCardResize = () => {

    }

    componentWillUnmount () {
        this._mounted = false;
        if (this.resizeListener) window.removeEventListener("resize", this.resizeListener)
    }

    loadCatalog = async () => {
        API.getCatalog(this.state.series).then(cards => {
           this.setState({loaded: true, catalog: cards});
        }).catch(console.error)
    }

    filterChange = change => {
        if (this.state.series != change.series) {
            this.setState(change, () => {
                this.loadCatalog();
            });
        } else {
            this.setState(change);
        }
    }

    render() {
        if (!this.state.loaded) return (<div className="container" id="catalog"><Loader /></div>)

        let { rarity, namevariant, search } = this.state;

        let cards = this.state.catalog;
        if (rarity && rarity.length > 0) cards = cards.filter(x => x.rarity?.toLowerCase() === rarity?.toLowerCase())
        if (namevariant && namevariant.length > 0) cards = cards.filter(x => x.namevariant === namevariant);
        if (search) cards = cards.filter(x => x.name?.toLowerCase().includes(search?.toLowerCase()));
        let {cardHeight, cardWidth} = this.state;
        if (this.state.mobile) {
            cardHeight = cardHeight / cardWidth * 150;
            cardWidth = 150;
        }
        cards = cards.map((card, i) => {
            let {front,back} = card.images;
            if (!front.includes("http")) front = "https://cloudflare-ipfs.com/ipfs/" + front;
            if (!back.includes("http")) back = "https://cloudflare-ipfs.com/ipfs/" + back;
            return (
                <div key={`${i}-${front}-${back}`} style={{width: cardHeight+"px", height: cardHeight + "px", marginBottom: "25px"}}>
                    <div className={"card-wrapper"} style={{width: cardWidth + "px", height: cardHeight + "px"}}>
                        <Card src={front} back={replaceIpfsHash(back)} height={cardHeight} width={cardWidth} portrait={["series2"].includes(this.state.series)} no3d={card.rarity == "Raw"} />
                    </div>
                </div>
            );
        });
        return (
            <div className="container" id="catalog">
                <div>
                    <h1>Search through all GPK Cards on WAX</h1>
                    <FilterBar onChange={this.filterChange} defaultState={
                        {
                            series: config.default_series,
                            rarity: "",
                            namevariant: "",
                            search: "",
                        }
                    }/>
                </div>
                { cards.length > 0 ? <Pagination wrapper={items => <div className="card-list">{ items }</div>} items={cards} /> : null }
                {cards.length === 0 ? <center>No cards found</center> : null}
            </div>
        );
    }
}
export default Catalog;