import BigNumber from 'big-number';
import {numericFromName} from '../../helpers/utils'

import * as API from '../API';

const contractName = API.SOCIAL_CONTRACT;

function dec2hex(str) { // .toString(16) only works up to 2^53
	let dec = str.toString().split(''), sum = [], hex = [], i, s
	while (dec.length) {
		s = 1 * dec.shift()
		for (i = 0; s || i < sum.length; i++) {
			s += (sum[i] || 0) * 10
			sum[i] = s % 16
			s = (s - sum[i]) / 16
		}
	}
	while (sum.length) {
		hex.push(sum.pop().toString(16))
	}
	return hex.join('')
}

export const follow = target => {
	let acts = [
		{
			account: contractName,
			name: 'follow',
			authorization: [{
				actor: global.wax.userAccount,
				permission: 'active',
			}],
			data: {
				"from": global.wax.userAccount,
				"to": target
			}
		}
	];
	return new Promise((resolve, reject) => {
		global.wax.api.transact({
			actions: acts
		}, {
			blocksBehind: 10,
			expireSeconds: 160
		}).then(result => {
			resolve(result)
		}).catch(err => {
			reject(err)
		});

	});
}

export const unfollow = target => {
	let acts = [
		{
			account: contractName,
			name: 'unfollow',
			authorization: [{
				actor: global.wax.userAccount,
				permission: 'active',
			}],
			data: {
				"from": global.wax.userAccount,
				"to": target
			}
		}
	];
	return new Promise((resolve, reject) => {
		global.wax.api.transact({
			actions: acts
		}, {
			blocksBehind: 10,
			expireSeconds: 160
		}).then(result => {
			resolve(result)
		}).catch(err => {
			reject(err)
		});

	});
}

export const getFollowing = accountName => {
	return new Promise((resolve, reject) => {
		if (!accountName) return reject();
		let numericAccountName = numericFromName(accountName);
		fetch("https://api.waxnet.io/v1/chain/get_table_rows",
			{
				"body": JSON.stringify({
					"json": true,
					"code": contractName,
					"scope": contractName,
					"table": "following",
					"table_key": "followed",
					"lower_bound": numericAccountName,
					"upper_bound": BigNumber(numericAccountName).plus(1).toString(),
					"index_position": 2,
					"key_type": "i64",
					"limit": 100,
					"reverse": false,
					"show_payer": false
				}),
				"method": "POST"
			}).then(res => res.json())
			.then(res => {
				resolve(res);
			});
	});
}


export const getFollowers = accountName => {
	return new Promise((resolve, reject) => {
		if (!accountName) return reject();
		let numericAccountName = numericFromName(accountName);
		fetch("https://api.waxnet.io/v1/chain/get_table_rows",
			{
				"body": JSON.stringify({
					"json": true,
					"code": contractName,
					"scope": contractName,
					"table": "following",
					"table_key": "follower",
					"lower_bound": numericAccountName,
					"upper_bound": BigNumber(numericAccountName).plus(1).toString(),
					"index_position": 3,
					"key_type": "i64",
					"limit": 100,
					"reverse": false,
					"show_payer": false
				}),
				"method": "POST"
			}).then(res => res.json())
			.then(res => {
				resolve(res);
			});
	});
}


export const isFollowing = (follower, followed) => {
	return new Promise((resolve, reject) => {
		if (!follower) return reject();
		if (!followed) return reject();
		let numericFollower = numericFromName(follower);
		let numericFollowed = numericFromName(followed);
		fetch("https://api.waxnet.io/v1/chain/get_table_rows",
			{
				"body": JSON.stringify({
					"json": true,
					"code": contractName,
					"scope": contractName,
					"table": "following",
					"table_key": "combined",
					"lower_bound": "0x" + dec2hex(`${numericFollower}${numericFollowed}`),
					"upper_bound": "0x" + dec2hex(`${BigNumber(`${numericFollower}${numericFollowed}`).plus(1).toString()}`),
					"index_position": 3,
					"key_type": "i128",
					"limit": 100,
					"reverse": false,
					"show_payer": false
				}),
				"method": "POST"
			}).then(res => res.json())
			.then(res => {
				resolve(res);
			}).catch(reject);
	});
}


export const findWalletByName = search => {
	return new Promise((resolve, reject) => {
		if (!search) return reject();

		let missing = "";
		for (let i = 0; i < (12 - search.length); i++) {
			missing += "z";
		}
		fetch("https://api.waxnet.io/v1/chain/get_table_by_scope",
			{
				"body": JSON.stringify({
					"code": "eosio",
					"table": "userres",
					"lower_bound": search,
					"upper_bound": search + missing,
					"limit": 50
				}),
				"method": "POST"
			}).then(res => res.json())
			.then(res => {
				resolve(res);
			});
	});
}
