import * as API from "../API";

const addToWishlist = (name, rarity) => {
    return new Promise((resolve, reject) => {
        let acts = [
            {
                account: API.WISHLIST_CONTRACT,
                name: 'add',
                authorization: [{
                    actor: global.wax.userAccount,
                    permission: 'active',
                }],
                data: {
                    "from": global.wax.userAccount,
                    "wish": name,
                    "rarity": rarity
                }
            }
        ];
            global.wax.api.transact({
                actions: acts
            }, {
                blocksBehind: 10,
                expireSeconds: 160
            }).then(result => {
                resolve(result)
            }).catch(err => {
                reject(err)
            });
    });
}

const removeFromWishlist = id => {
    return new Promise((resolve, reject) => {
        let acts = [
            {
                account: API.WISHLIST_CONTRACT,
                name: 'remove',
                authorization: [{
                    actor: global.wax.userAccount,
                    permission: 'active',
                }],
                data: {
                    "from": global.wax.userAccount,
                    "id": id,
                }
            }
        ];
            global.wax.api.transact({
                actions: acts
            }, {
                blocksBehind: 10,
                expireSeconds: 160
            }).then(result => {
                resolve(result)
            }).catch(err => {
                reject(err)
            });
    });
}

const getWishlist = accountName => {
    return new Promise((resolve, reject) => {
        fetch("https://api.waxnet.io/v1/chain/get_table_rows",
            {
                "body":JSON.stringify({
                    "json":true,
                    "code": API.WISHLIST_CONTRACT,
                    "scope": accountName,
                    "table":"wishs",
                    "key_type":"i64",
                    "limit":100,
                    "reverse":false,
                    "show_payer":false
                }),
                "method":"POST"}).then(res => res.json())
            .then(res => {
                resolve(res);
            });
    });
}

export default {
    addToWishlist,
    removeFromWishlist,
    getWishlist
}
