import React, { Component } from 'react'

import * as API from "../helpers/API";
import * as ActivityAPI from "../helpers/activity";
import Packs from "../helpers/packs";
import { Link } from 'react-router-dom';

import TradeIcon from '../assets/icons/trade.svg';
import FollowIcon from '../assets/icons/follow.svg';
import UnpackIcon from '../assets/icons/unpack.svg';
import UnknownIcon from '../assets/icons/help.svg';

export default class ActivityFeed extends Component {

    _mounted = false;

    state = {activity: []}

    componentDidMount () {
        this._mounted = true;
        if (!this.props.recentActions) {
            this.loadActivity();
        } else {
            this.setState({activity: this.props.recentActions.sort((a, b) => {
                if (new Date(b.time).getTime() > new Date(a.time).getTime()) return 1;
                if (new Date(b.time).getTime() < new Date(a.time).getTime()) return -1;
                if (new Date(b.time).getTime() === new Date(a.time).getTime()) return 0;
            })})
        }
    }

    componentWillUnmount () {
        this._mounted = false;
    }

    loadActivity = () => {
        ActivityAPI.getRecentActions(this.props.accountName).then(res => {
            if (this._mounted) {
                this.setState({activity: res.sort((a, b) => {
                    if (new Date(b.time).getTime() > new Date(a.time).getTime()) return 1;
                    if (new Date(b.time).getTime() < new Date(a.time).getTime()) return -1;
                    if (new Date(b.time).getTime() === new Date(a.time).getTime()) return 0;
                })});
            }
        }).catch(console.error)
    }

    timeSince = (date) => {

        let timeZoneOffset = new Date().getTimezoneOffset() *- 1 / 60;

        var seconds = Math.floor((new Date() - date) / 1000);
        seconds = seconds - (timeZoneOffset * 3600)
      
        var interval = Math.floor(seconds / 31536000);
      
        if (interval > 1) {
          return interval + " years";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
          return interval + " months";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
          return interval + " days";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
          return interval + " hours";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
          return interval + " minutes";
        }
        return Math.floor(seconds) + " seconds";
      }

    phrase = (account, name, data) => {
        if (account === API.SOCIAL_CONTRACT && name === "follow") return <span> followed <Link to={"/p/" + data.to}>{ data.to }</Link></span>;
        if (account === API.UNPACKING_CONTRACT && name === "unbox") {
            let packs = Packs.filter(({ type }) => type === data.type);
            if (!packs  || packs.length === 0) return " opened a pack!";
            let pack = packs[0];
            return <span> opened <Link to={"/shop"}>{ pack.series } <small>({ pack.edition })</small></Link></span>
        }
        if (account === "trade" && name === "createprop") return <span> sent a trade to <Link to={"/p/" + data.account_to}>{ data.account_to }</Link></span>
        if (account === "trade" && name === "acceptprop") return <span> accepted a trade.</span>
        if (account === "trade" && name === "creategift") return <span> sent a gift to <Link to={"/p/" + data.account_to}>{ data.account_to }</Link></span>
        if (account === "trade" && name === "requestgift") return <span> requested a gift from <Link to={"/p/" + data.account_to}>{ data.account_to }</Link></span>
        return <small>{ account }::{ name }</small>
    }

    icon = (account, name) => {
        if (account === API.SOCIAL_CONTRACT && name === "follow") return FollowIcon;
        if (account === API.UNPACKING_CONTRACT && name === "unbox") return UnpackIcon;
        if (account === "trade" && (name === "createprop" || name === "acceptprop")) return TradeIcon;
        return UnknownIcon;
    }

    render() {
        return (
            <div className={"ActivityFeed"}>
                <div className={"title"}>{ this.props.title ? this.props.title : "Activity Feed" }</div>
                <div className={"feed"}>
                    {
                        this.state.activity.map((act, i) => {
                            // let timeAgo = new Date().getTime() - act.time;
                            return (
                                <div key={i} className="box">
                                    <img src={this.icon(act.account, act.name)} alt={act.account + "::" + act.name} />
                                    <Link to={"/p/" + act.auth[0]} className={"name"}>{ act.auth.join(" and ") } </Link>
                                    { this.phrase(act.account, act.name, act.data) }
                                    <a href={"https://wax.bloks.io/transaction/" + act.tx} target="_blank" className={"tx"}><small className={"date"}>{ this.timeSince(act.time) } ago</small> { act.tx.substring(0, 6) }</a>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}
