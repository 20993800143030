import { contracts } from "../API";

import DevModeHelper from "../DevModeHelper";

global.devMode = DevModeHelper.devMode;

const products = [
	{
		"name": "Series 2",
		"edition": "Standard Pack",
		"image": "QmWkB8bBEoHai7Li5jHdUbavFKxnfQmVvGJj5ytyWpNbUt/1.jpg",
		"content": [
			"4 Slime Cards",
			"1 Raw Cards",
			"50% chance at a Returning Card",
			"30% chance at a Sketch Card",
			"1% chance at a Collector's Edition"
		],
		"cardCount": 8,
		"price": 999,
		"stripeObject": global.devMode ? "price_1HVEJ5DCiNMcz1G7bcfCMTvF" : "price_1HVEJMDCiNMcz1G7knnU3NgV",
		"contract": contracts.TOKEN,
		"token": "GPKTWOA",
		"minPurchase": 1,
		"date": "Sept 30, 2020",
		"maxPurchase": 100,
		"release": global.devMode ? new Date().getTime() + 1000 * 30 : new Date("Sept 30 2020 9:00:00 PDT").getTime(),
	},
	{
		"name": "Series 2",
		"edition": "Mega Pack",
		"image": "QmWkB8bBEoHai7Li5jHdUbavFKxnfQmVvGJj5ytyWpNbUt/2.jpg",
		"content": [
			"4 Gum Cards",
			"3 Raw Cards",
			"2 Returning Cards",
			"1 Returning \"A\" Name Card",
			"1 Sketch Card",
			"4% chance at a Collector's Edition"
		],
		"cardCount": 25,
		"price": 2499,
		"stripeObject": global.devMode ? "price_1HVEM0DCiNMcz1G7jyKYAVTE" : "price_1HVEM8DCiNMcz1G723xHUa5W",
		"contract": contracts.TOKEN,
		"token": "GPKTWOB",
		"minPurchase": 1,
		"maxPurchase": 40,
		"date": "Sept 30, 2020",
		"release": global.devMode ? new Date().getTime() + 1000 * 30 : new Date("Sept 30 2020 9:00:00 PDT").getTime(),
	},
	{
		"name": "Series 2",
		"edition": "Ultimate Pack",
		"image": "QmWkB8bBEoHai7Li5jHdUbavFKxnfQmVvGJj5ytyWpNbUt/3.jpg",
		"content": [
			"8 Raw Cards",
			"7 Returning Cards",
			"3 Sketch Card",
			"2 VHS Card",
			"10% chance at a Collector's Edition"
		],
		"cardCount": 55,
		"price": 4999,
		"stripeObject": global.devMode ? "price_1HVENiDCiNMcz1G7f18PQmNt" : "price_1HVENkDCiNMcz1G7uEVDhvLq",
		"contract": contracts.TOKEN,
		"token": "GPKTWOC",
		"minPurchase": 1,
		"maxPurchase": 20,
		"date": "Sept 30, 2020",
		"release": global.devMode ? new Date().getTime() + 1000 * 30 : new Date("Sept 30 2020 9:00:00 PDT").getTime(),
	},
	

	/**
	 * 
	 * EXOTIC SERIES
	 * 
	 */
	// {
	// 	"name": "GPK Goes Exotic",
	// 	"edition": "Standard Pack",
	// 	"image": "QmZBXd6CWeSYc6ZxDcRPC54dwZv4NeSBoRahY8bYDdYPui",
	// 	"content": [
	// 		"60% chance at a \"B\" Name Prism Card",
	// 		"40% chance at an \"A\" Name Prism Card",
	// 		"10% chance at a Tiger Stripe Card",
	// 		"1% chance at a Collector's Edition or Tiger Claw Card",
	// 	],
	// 	"cardCount": 5,
	// 	"price": 499,
	// 	"stripeObject": global.devMode ? "price_1H03HJDCiNMcz1G78rnB5os2" : "price_1H33t0DCiNMcz1G702HirjbR",
	// 	"contract": contracts.TOKEN,
	// 	"token": "EXOFIVE",
	// 	"minPurchase": 2,
	// 	"date": "July 14, 2020"
	// },
	// {
	// 	"name": "GPK Goes Exotic",
	// 	"edition": "Mega Pack",
	// 	"image": "QmNjTxU8DBN7us9cUt5y9Uju5b7KEQa4Uwj7FhsuAZ79HQ",
	// 	"content": [
	// 		"4 \"B\" Name Prism Cards",
	// 		"3 \"A\" Name Prism Cards",
	// 		"50% chance at a Tiger Stripe Card",
	// 		"5% chance at a Collector's Edition or Tiger Claw Card",
	// 	],
	// 	"cardCount": 25,
	// 	"price": 1999,
	// 	"stripeObject": global.devMode ? "price_1H03HvDCiNMcz1G7F9yCUw10" : "price_1H33smDCiNMcz1G74SzGk7jS",
	// 	"contract": contracts.TOKEN,
	// 	"token": "EXOMEGA",
	// 	"minPurchase": 1,
	// 	"date": "July 14, 2020"
	// },


	// /**
	//  * 
	//  * Series 1
	//  * 
	//  */
	// {
	// 	"name": "Series 1",
	// 	"edition": "Standard Pack",
	// 	"image": "QmSRti2HK95NXWYG3t3he7UK7hkgw8w9TdqPc6hi5euV1p/packs/standard.jpg",
	// 	"content": [
	// 		"1 \"B\" Name Prism Card",
	// 		"50% chance at an \"A\" Name Prism Card",
	// 		"10% chance at a \"B\" Name Sketch Card",
	// 		"5% chance at an \"A\" Name Sketch Card ",
	// 		"0.2% chance at a Collector's Edition Card",
	// 	],
	// 	"cardCount": 5,
	// 	"price": 499,
	// 	"contract": contracts.TOKEN,
	// 	"token": "GPKFIVE",
	// 	"minPurchase": 2,
	// 	"date": "May 12, 2020"
	// },
	// {
	// 	"name": "Series 1",
	// 	"edition": "Mega Pack",
	// 	"image": "QmSRti2HK95NXWYG3t3he7UK7hkgw8w9TdqPc6hi5euV1p/packs/mega.jpg",
	// 	"content": [
	// 		"5 \"B\" Name Prism Cards",
	// 		"3 \"A\" Name Prism Cards",
	// 		"1 \"B\" Name Sketch Card",
	// 		"50% chance at an \"A\" Name Sketch Card ",
	// 		"1% chance at a Collector's Edition Card",
	// 	],
	// 	"cardCount": 30,
	// 	"price": 2499,
	// 	"contract": contracts.TOKEN,
	// 	"token": "GPKMEGA",
	// 	"minPurchase": 1,
	// 	"date": "May 12, 2020"
	// },
];

export default {
	getProducts: () => {
		return new Promise(resolve => resolve(products))
	},
	getProductByToken: token => {
		let p = products.filter(product => product.token == token);
		if (p && p.length) {
			return (p[0])
		} else {
			return (null)
		}
	}
};