import React, { Component } from 'react'

import "./Inventory.css";

import * as API from "../../helpers/API";
import { Link } from 'react-router-dom';

import AuxLayout from "../../helpers/AuxLayout";
import ActionCard from '../ActionCard';

import ShoppingCartIcon from '../../assets/icons/cart.svg';
import TradeIcon from '../../assets/icons/trade.svg';
import FilterBar from '../FilterBar';
import CardView from '../CardView';

import Inspect from '../Inspect/Inspect';
import Loader from '../Loader';
import Pagination from '../Pagination';
import Swal from 'sweetalert2';
import CatalogHelper from '../../helpers/CatalogHelper';

export default class InventoryView extends Component {

	_mounted = false;

	state = {
		loaded: false,
		items: [],

		// Filter
		series: "series1",
		rarity: "",
		namevariant: "",
		search: "",
		special_filter: "",
		//
		inspect: null,
		highlighted: []
	};

	componentDidMount() {
		this._mounted = true
		this.loadInventories(this.props.accountName);
		let highlighted = localStorage.getItem("inv_highlighted");
		if (highlighted) {
			this.setState({ highlighted: highlighted.split(",") })
		}
	}

	componentWillUnmount() {
		this._mounted = false
	}

	loadInventories = accountName => {
		API.getInventory(accountName).then(inv => {
			API.getTradeInventory(accountName).then(res => {
				let tempInv = res.rows;
				tempInv = tempInv.map(nft => {
					nft.trade_id = nft.id;
					nft.trade_owner = accountName;
					return nft;
				});
				if (this._mounted) {
					this.setState({ loaded: true, items: [...inv, ...tempInv] })
				}
			}).catch(err => {
				console.error(err)
				if (this._mounted) {
					this.setState({ loaded: true, items: inv })
				}
			})
		}).catch(console.error)
	}

	withdraw = async () => {
		if (this.state.inspect && this.state.inspect.id != null) {
			try {
				await API.withdrawNFTs(this.state.inspect.id)
				if (this._mounted) {
					this.setState({ inspect: null });
				}
				await Swal.fire({
					title: 'Card withdrawn!',
					icon: 'success',
					confirmButtonText: 'Ok'
				});
				setTimeout(() => {
					this.setState({ loaded: false, inspect: null }, () => {
						this.loadInventories(this.props.accountName);
					});
				}, 1000);
			} catch (err) {
				await Swal.fire({
					title: 'Withdrawing card failed.',
					text: err.toString(),
					icon: 'error',
					confirmButtonText: 'Ok'
				});
			}
		}
	}

	filterChange = change => this.setState(change);

	render() {
		let items = this.state.items.map(item => {
			try {
				item.mdata = JSON.parse(item.mdata)
			} catch (e) {
			}
			try {
				item.idata = JSON.parse(item.idata)
			} catch (err) {
			}
			if (!item.idata) item.idata = {};
			item.mdata = {
				...item.idata,
				...item.mdata
			}
			if (item.mdata.name) item.name = item.mdata.name;
			if (item.mdata.img) item.img = item.mdata.img;
			if (item.mdata.image) item.img = item.mdata.image;
			if (item.img && !item.img.includes("http")) item.img = "https://cloudflare-ipfs.com/ipfs/" + item.img;
			return item;
		}).sort((item1, item2) => {
			const a = parseInt(item1.id);
			const b = parseInt(item2.id);
			if (a > b)
				return -11;
			if (b > a)
				return 1;
			return 0;
		})

		if (this.state.loaded && items.length === 0 && global.wax && this.props.accountName === global.wax.userAccount) return (
			<AuxLayout>
				<Link to={"/shop"}><ActionCard className={"half small"} img={ShoppingCartIcon} text={"Buy Packs"} /></Link>
				<Link to={"/trade"}><ActionCard className={"half small"} img={TradeIcon} text={"Trade"} /></Link>
			</AuxLayout>
		);
		if (this.state.loaded && items.length === 0) return <center><small><b>{this.props.accountName}</b> doesn't have any cards in their inventory
			yet.</small></center>;
		let { rarity, namevariant, search, special_filter, series } = this.state;
		if (special_filter != "") {
			if (special_filter === "trade") items = items.filter(x => x.in_wet)
			if (special_filter === "missing") {
				let tempSelectors = items.map(x => (x.mdata.cardid + x.mdata.quality + x.mdata.variant).toLowerCase());
				items = (CatalogHelper.getSeries("series1").filter(card => {
					if (tempSelectors.includes((card.variant + card.namevariant + card.rarity).toLowerCase())) return false;
					return true;
				}).map(card => {
					let nftMdata = {
						backimg: card.images.back,
						cardid: card.variant,
						img: card.images.front,
						name: card.name,
						quality: card.namevariant,
						variant: card.rarity.toLowerCase()
					};
					let nft = {
						author: "gpk.topps",
						category: "series1",

						// shortcut nft data
						img: card.images.front,
						name: card.name,

						owner: null,
						idata: {},
						mdata: nftMdata,
						assetid: null,
						requireclaim: 0,
					}
					return nft;
				}));
			}
			if (special_filter === "duplicates") {
				let checked = [];
				items = items.filter(x => {
					let q = (x.mdata.cardid + x.mdata.quality + x.mdata.variant).toLowerCase();
					if (checked.includes(q)) {
						return true;
					}
					checked.push(q);
					return false;
				})
			}
		}


		if (rarity && rarity.length > 0) items = items.filter(x => x.mdata.variant === rarity || x.idata.variant === rarity)
		if (namevariant && namevariant.length > 0) items = items.filter(x => x.mdata.quality === namevariant || x.idata.quality === namevariant);
		if (series && series.length > 0) items = items.filter(x => x.category === series);
		if (search) items = items.filter(x => x.name.toLowerCase().includes(search.toLowerCase()));
		items = items.map(nft => {
			if (nft.mdata.img && !nft.mdata.img.includes("http")) nft.mdata.img = "https://cloudflare-ipfs.com/ipfs/" + nft.mdata.img
			if (nft.img && !nft.img.includes("http")) nft.img = "https://cloudflare-ipfs.com/ipfs/" + nft.img;
			return nft;
		})
		return (
			<AuxLayout>
				<FilterBar onChange={this.filterChange} wetSettings={true} />
				<Pagination wrapper={items => (
					<div id="invview" className={"card-wrapper"}>
						{!this.state.loaded ? <Loader /> : null}
						{items}
						{
							this.state.loaded && items.length === 0 ? <center><small>No cards found.</small></center> : null
						}
						<Inspect specialFilter={special_filter} withdraw={this.withdraw} type={"card"} active={this.state.inspect}
								 close={() => this.setState({ inspect: null })} {...this.state.inspect} />
					</div>
				)} items={items.map((item, i) => <CardView key={i} name={item.name} img={item.img} back={item.mdata.backimg}
														   onClick={() => this.setState({ inspect: item })} no3d={item.mdata.variant == "raw"} />)} />
			</AuxLayout>
		)
	}
}
