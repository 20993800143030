import React, {Component} from 'react';

import "./Profile.css";

import * as SocialAPI from "../../helpers/contracts/social";

import AuxLayout from "../../helpers/AuxLayout";
import {withRouter, Link, Route, NavLink} from "react-router-dom";
import {connect} from "react-redux";

import CreateOffer from '../../components/CreateOffer/CreateOffer';
import FollowerList from '../../components/Social/FollowerList';
import FollowedList from '../../components/Social/FollowedList';
import InventoryView from '../../components/Inventory/InventoryView';
import WishlistView from '../../components/Inventory/WishlistView';
import PackInventoryList from '../../components/Inventory/PackInventoryList';
import ActivityFeed from '../../components/ActivityFeed';
import Inventory from '../Inventory/Inventory';
import Button from "../../components/Button/Button";
import Swal from "sweetalert2";
import config from '../../config/config';

class TradeOffer extends Component {
    _mounted = false;
    state = {};
    
    constructor (props) {
        super(props);
        this._lastName = props.match.params.uid;
        this.state = {
            nick: null,
            name: props.match.params.uid,
            avatar: config.media.LOGO,
            loading: false,
            following: false,
            stats: {
                followers: "0",
                following: "0",
                wishlist: "0",
            },
            tx_status: "",
        };
    }

    componentDidUpdate () {
        if (this._lastName !== this.props.match.params.uid) {
            this._lastName = this.props.match.params.uid;
            this.fetchProfile(this.props.match.params.uid);
        }
    }

    componentDidMount() {
        this._mounted = true;
        let accountName = this.props.match.params.uid;
        this.fetchProfile(accountName);
    }

    fetchProfile = accountName => {
        if (!this._mounted) return;
        this.setState({
            name: accountName
        })
        this.loadStats(accountName);
    }

    loadStats = accountName => {
        SocialAPI.getFollowers(accountName).then(data => {
            let followers = data.rows.length;
            if (data.more) followers += "+";
            if (!this._mounted) return;
            let followingAccount = false;
            if (data.rows.map(followers => followers.follower).includes(this.props.userAccount)) followingAccount = true;
            this.setState({
                stats: {
                    ...this.state.stats,
                    followers: followers
                },
                following: followingAccount
            });

        })
        SocialAPI.getFollowing(accountName).then(data => {
            let following = data.rows.length;
            if (data.more) following += "+";
            if (!this._mounted) return;
            this.setState({
                stats: {
                    ...this.state.stats,
                    following: following
                }
            })
        }).catch(console.error)
    }

    componentWillUnmount() {
        this._mounted = false;
    }
    
    follow = () => {
        this.setState({ loading:true });
        SocialAPI.follow(this.state.name).then(res => {
            this.setState({following: true})
            this.setState({ loading:false});
        }).catch(e => {
            Swal.fire({
                title: 'Following Failed',
                text: 'Make sure you\'re logged in to follow.',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            this.setState({ loading:false});
            console.error(e);
        });
    }
    unfollow = () => {
        this.setState({ loading:true});
        SocialAPI.unfollow(this.state.name).then(res => {
            this.setState({following: false})
            this.setState({ loading:false});
        }).catch(console.error);
    }


    render() {
        return (
            <div className="container" id="profile">
                <div className={"box"}>
                    <div className={"basic"}>
                        <img className={"avatar"} src={this.state.avatar} alt={this.state.name} />
                        <div className={"name"}>
                            <Link to={"/p/" + this.state.name}>
                                <span className={"nick"}>{this.state.nick ? this.state.nick : this.state.name}</span>
                                {
                                    this.state.nick ?
                                        <AuxLayout>
                                            <br />
                                            <small className={"accountName"}><i>({this.state.name})</i></small>
                                        </AuxLayout>
                                    : null
                                }
                            </Link>
                        </div> 
                    </div>
                    <div className={"stats"}>
                        <Link to={`/p/${this.state.name}/following`} className={"data-box"}>
                            <div className={"nr"}>{ this.state.stats.following }</div>
                            <div className={"info"}>following</div>
                        </Link>
                        <Link to={`/p/${this.state.name}/followers`} className={"data-box"}>
                            <div className={"nr"}>{ this.state.stats.followers }</div>
                            <div className={"info"}>followers</div>
                        </Link>
                    </div>
                    {
                        this.state.name !== this.props.userAccount ?
                            <div className={"actions"}>
                            <Link to={"/p/" + this.state.name + "/trade"}><button className={!this.state.following ? "secondary" : ""}>Send trade offer</button></Link>
                                {
                                    !this.state.following ? 
                                    <Button className={'small-loader'} loading={this.state.loading ? 1 : 0} onClick={this.follow}>Follow</Button>
                                    :
                                    <Button loading={this.state.loading ? 1 : 0} onClick={this.unfollow} className={"followed gray small-loader"}>FOLLOWED</Button>
                                }
                            </div>
                        : null
                    }
                    <div className={"tx-error"}>{this.state.tx_status}</div>
                </div>
                <div className="profile-links">
                    <NavLink to={"/p/" + this.state.name + "/activity"}>Activity</NavLink>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink exact to={"/p/" + this.state.name + "/"}>Inventory</NavLink>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <NavLink to={"/p/" + this.state.name + "/wishlist"}>Wishlist</NavLink>
                </div>
                <Route exact path={"/p/:uid/"} component={() => <Inventory accountName={this.state.name} noCatalog={true}/>}/>
                <Route path={"/p/:uid/activity"} component={() => <ActivityFeed accountName={this.state.name} />}/>
                <Route path={"/p/:uid/wishlist"} component={() => <AuxLayout>
                <div className={"title"}><i>Wishlist</i></div>
                    <WishlistView accountName={this.state.name} />
                </AuxLayout>}/>
                <Route path={"/p/:uid/trade"} component={props => <CreateOffer accountName={this.state.name} {...props} />}/>
                <Route path={"/p/:uid/followers"} component={() => <FollowerList accountName={this.state.name} link={"/p/:uid"} />}/>
                <Route path={"/p/:uid/following"} component={() => <FollowedList accountName={this.state.name} link={"/p/:uid"} />}/>
            </div>
    );
    }
}
const mapStateToProps  = state => {
    return {
        userAccount: state.userAccount
    }
}

export default connect(mapStateToProps)(withRouter(TradeOffer));