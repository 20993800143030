import React, { Component } from 'react'

import './FAQ.css';

import FAQHelper from "../../helpers/faq"
import AuxLayout from '../../helpers/AuxLayout';

export default class FAQ extends Component {


    renderFAQ () {
        return FAQHelper.map(({question, answer}, i) => {
            question = typeof question === "string" ? <span>{ question }</span> : question;
            answer = typeof answer === "string" ? <span>{ answer }</span> : answer;
            return (
                <AuxLayout key={i}>
                    <div className={"question"}>
                        <b>Q:</b> { question }
                    </div>
                    <div className={"answer"}>
                        <b>A:</b> {answer}
                    </div>
                </AuxLayout>
            );
        })
    }

    render() {
        return (
            <div className="container" id="faq" style={this.props.style}>
                <h2 className={"title"}>Frequently Asked Questions: GPK Cards on WAX</h2>
                <iframe width="100%" height="560" src="https://www.youtube-nocookie.com/embed/7qXtHSYdm5s" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{display: "block", margin: "15px auto", maxWidth: "100%"}}></iframe>
                { this.renderFAQ() }
            </div>
        )
    }
}
