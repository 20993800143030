import React, { Component } from 'react'

import './Social.css';

import FollowingItem from '../../components/Community/FollowingItem';

import * as SocialAPI from "../../helpers/contracts/social";
import { Link } from 'react-router-dom';

import CommunityIcon from '../../assets/icons/community.svg';
import ActionCard from '../ActionCard';

export default class FollowedList extends Component {
    _mounted = false;

    state = {following: [], loaded: false};

    componentDidMount () {
        this._mounted = true
        SocialAPI.getFollowing(this.props.accountName).then(data => {
            if (!this._mounted) return;
            this.setState({following: data.rows, loaded: true})
        }).catch(e => {
            this.setState({loaded: true})
        });
    }

    componentWillUnmount () {
        this._mounted = false
    }

    render() {
        if (this.state.loaded &&this.state.following.length === 0) return <Link to={"/community"} style={this.props.easyFix ? {width: "100%"} : {}}><ActionCard style={this.props.easyFix ? {marginRight: "0", width: "100%"} : {}} className={"full small"} img={CommunityIcon} text={"Find people"}/></Link>;
        return <div className="following-wrapper" style={{display: "flex", overflowY: "auto"}}>
            {
                this.state.following.map((friendship, i) => (
                    <FollowingItem key={i} accountName={friendship.followed} isTrade={true} />
                ))
            }
        </div>
        }
}
