import React, { Component } from 'react'

import { isAndroid, isChrome, isChromium, isIOS } from 'react-device-detect';

import * as VanillaTilt from 'vanilla-tilt';

import './Pack.css';

class Pack extends Component {


	sizerElement = React.createRef();

	constructor(props) {
		super(props);

		let back = ``;
		if (props.back) back = props.back;

		const isMega = (props.src.indexOf('/mega') > 0);
		const isExotic = (
			(props.src.indexOf('QmNjTxU8DBN7us9cUt5y9Uju5b7KEQa4Uwj7FhsuAZ79HQ') >= 0) ||
			(props.src.indexOf('QmZBXd6CWeSYc6ZxDcRPC54dwZv4NeSBoRahY8bYDdYPui') >= 0)
		);

		this.state = {
			showBackside: false,
			back: back,
			isExotic,

			width: props.width ? props.width : 0,
			height: props.height ? props.height : 0,
			forcedSizes: props.width !== null || props.height !== null,

			packClass: isMega ? 'pack-mega' : 'pack-standard',
			cleanImg: this.props.src
		}

	}

	componentDidMount() {
		this.initTilt();
		this.resizeListener = window.addEventListener("resize", () => {
			this.updateSizes();
		})
	}

	componentWillUnmount() {
		if (this.resizeListener) {
			window.removeEventListener("resize", this.resizeListener)
			delete this.resizeListener;
		}
	}

	componentDidUpdate() {
		this.updateSizes();
	}

	initTilt_(node) {
		VanillaTilt.init(node, {
			max: 25,
			gyroscope: true,
			startY: 9,

			perspective: 1400,
			easing: "cubic-bezier(.03,.98,.52,.99)",
			speed: (isChrome || isChromium) ? 1200 : 0,
			glare: true,
			maxGlare: 0.05,
			scale: 1.07
		});
	}

	initTilt() {
		this.initTilt_(this.rootNode);
		this.initTilt_(this.rootNodeBack);
	}

	updateSizes = () => {
		if (this.sizerElement && this.sizerElement.current && this.state.width !== this.sizerElement.current.width) {
			this.setState({
				width: this.sizerElement.current.width,
				height: this.sizerElement.current.height
			})
		}
	}

	render() {

		if (isIOS || isAndroid) {
			return (
				<img src={this.props.src} style={{ maxWidth: '100%' }} />
			)
		}

		return (
			<div className={"tilt3-wrapper tilt3-flip-back"} style={{ display: "inline-block", transform: "translateZ(-1px)" }}>
				<img src={this.props.src}
					 alt=""
					 style={{ maxWidth: '100%' }}
					 ref={this.sizerElement}
					 onLoad={() => this.updateSizes()}
					 className="tilt3-img-sizer"
				/>
				<div className={"tilt3-front " + this.state.packClass} style={{ width: `${this.state.width}px`, height: `${this.state.height}px` }}>
					<div className="tilt3-rotator" ref={node => (this.rootNode = node)}>
						<div className="card-pack" style={{ backgroundImage: `url(${this.state.cleanImg})` }}>
							<div className="glass">
								<div className="shine" />
							</div>
							<div className="glass glass-back" />
							<div className="glass glass-bottom" />
							<div className="glass glass-top" />
							<div className="glass glass-right" />
							<div className="glass glass-left" />
							<div className="inner" style={{ backgroundImage: `url(${this.state.cleanImg})` }} />
							<div className="inner card-pack-shadow" />
							<div className="inner card-pack-inner-1 shadow" />
							<div className="inner card-pack-inner-1"
								 style={{ backgroundImage: `url(${this.state.cleanImg})` }} />
							{
								(() => {
									if (this.state.isExotic) {
										return []
									} else {
										return [
											<div className="inner card-pack-inner-1 up-shadow"
												 style={{ backgroundImage: `url(${this.state.cleanImg})` }} />,
											<div className="inner card-pack-inner-2 shadow" />,
											<div className="inner card-pack-inner-2"
												 style={{ backgroundImage: `url(${this.state.cleanImg})` }} />,
											<div className="inner card-pack-inner-3 shadow" />,
											<div className="inner card-pack-inner-3"
												 style={{ backgroundImage: `url(${this.state.cleanImg})` }} />,
											<div className="inner card-pack-inner-4 shadow" />,
											<div className="inner card-pack-inner-4"
												 style={{ backgroundImage: `url(${this.state.cleanImg})` }} />,
										]
									}
								})()
							}
						</div>
					</div>
				</div>
			</div>
		)
	}

}

export default Pack;
