import React, { Component } from 'react'

import ForceReloadIcon from '../assets/icons/refresh.svg';
import AuxLayout from '../helpers/AuxLayout';
import config from '../config/config';


export default class FilterBar extends Component {

    lastState = null
    state = {
        series: config.default_series,
        rarity: "",
        namevariant: "",
        search: "",
        special_filter: false,
    };
    constructor (props) {
        super(props);
        this.state = {
            ...this.state,
            ...props.defaultState
        }
    }

    componentDidUpdate () {
        if (this.lastState != this.state) {
            this.lastState = this.state;
            if (this.props.onChange) this.props.onChange(this.state)
        }
    }
    
    searchInput = e => {
        this.setState({search: e.target.value})
    }

    variantChange = e => {
        this.setState({namevariant: e.target.value})
    }

    seriesChange = e => {
        this.setState({series: e.target.value})
    }

    rarityChange = e => {
        this.setState({rarity: e.target.value})
    }
    specialFilterChange = e => {
        this.setState({special_filter: e.target.value})
    }

    render() {
        return (
            <div className="box" id="FilterBar">
                <input type="search" onKeyDown={this.searchInput} onInput={this.searchInput} placeholder="Search..." />
                    {this.props.wetSettings ? (
                        <select name="special_filter" id="special_filter" defaultValue={this.state.special_filter} onChange={this.specialFilterChange}>
                            <option value={""}>Show All</option>
                            <option value={"trade"}>In Trade</option>
                            <option value={"missing"}>Missing</option>
                            <option value={"duplicates"}>Duplicates</option>
                        </select>
                    ) : null}
                    <select name="rarity" id="rarity" defaultValue={this.state.rarity} onChange={this.rarityChange}>
                        <option value={""}>All Rarities</option>
                        <option value={"base"}>Base</option>
                        {/* Raw */}  { ["series2"].includes(this.state.series) && <option value={"raw"}>Raw</option> }
                        {/* Prism */}  { ["series1", "exotic"].includes(this.state.series) && <option value={"prism"}>Prism</option> }
                        {/* Slime */}  { ["series2"].includes(this.state.series) && <option value={"slime"}>Slime</option> }
                        {/* Gum */}  { ["series2"].includes(this.state.series) && <option value={"gum"}>Gum</option> }
                        {/* Sketch */}  { ["series1", "series2"].includes(this.state.series) && <option value={"sketch"}>Sketch</option> }
                        {/* VHS */}  { ["series2"].includes(this.state.series) && <option value={"vhs"}>VHS</option> }
                        {/* Returning */}  { ["series2"].includes(this.state.series) && <option value={"returning"}>Returning</option> }
                        {/* Original Art */}  { ["series2"].includes(this.state.series) && <option value={"originalart"}>Original Art</option> }
                        {/* Tiger Stripe */}  { ["exotic"].includes(this.state.series)  && <option value={"tigerborder"}>Tiger Stripe</option> }
                        {/* Tiger Scratch */}  { ["exotic"].includes(this.state.series) && <option value={"tigerscratch"}>Tiger Claw</option> }
                        {/* Collectors */}  <option value={"collector"}>Collector's Edition</option>
                        {/* Golden */}  { ["series1"].includes(this.state.series) && (
                            <AuxLayout>
                                <option value={""} disabled>───────</option>
                                <option value={"golden"}>⋆ Golden</option>
                            </AuxLayout> 
                        )}
                    </select>
                    <select name="series" className="longer" defaultValue={this.state.namevariant} id="series" onChange={this.variantChange}>
                        <option value={""}>All Variants</option>
                        <option value={"a"}>"A" Variant</option>
                        <option value={"b"}>"B" Variant</option>
                        <option value={"c"}>"C" Variant</option>
                    </select>
                    <select name="series" id="series" defaultValue={this.state.series} onChange={this.seriesChange}>
                        <option value={"series1"}>Series 1</option>
                        <option value={"exotic"}>GPK Goes Exotic</option>
                        <option value={"series2"}>Series 2</option>
                    </select>
            </div>
        )
    }
}
