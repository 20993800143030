import React, { Component } from 'react';

import "./LandingPage.css";

import Globe from "../../assets/images/earth.png";
import Chart from "../../assets/images/chart.png";

import * as actionTypes from "../../store/actionTypes";
import { connect } from "react-redux";
import AuxLayout from "../../helpers/AuxLayout";
import { Link, Redirect } from "react-router-dom";

import Card from "../../components/Card/Card";
import config from '../../config/config';

class LandingPage extends Component {

	state = { redirect: null }

	login = async () => {
		let userAccount = await global.wax.login();
		if (userAccount) {
			this.setState({ redirect: "/shop" })
			this.props.onAuthUpdate(userAccount);
		}
		global.wax.whitelistedContracts = global.whitelistedContracts;
	}

	render() {
		if (this.state.redirect) return <Redirect to={this.state.redirect} />;
		return (
			<div className={"landing"}>
				<div id={"landingpage"}>
					<h1>
						<img className="topps" src={config.media.BRAND} alt="Topps" />
						<br />
						On the <b>WAX BLOCKCHAIN</b> since <b>2020</b>
					</h1>
					<div className="brand">
						<div className="cards">
							<img src={`https://cloudflare-ipfs.com/ipfs/QmTfjqPkozhsrAa7DY9N97S1Nwe1AFuCvuFCySHswCEQzS/base/42a.jpg`} alt=""
								 className="card" />
							<img src={`https://cloudflare-ipfs.com/ipfs/QmTfjqPkozhsrAa7DY9N97S1Nwe1AFuCvuFCySHswCEQzS/raw/45.jpg`} alt=""
								 className="card" />
							<img src={`https://cloudflare-ipfs.com/ipfs/QmTfjqPkozhsrAa7DY9N97S1Nwe1AFuCvuFCySHswCEQzS/vhs/53b.gif`} alt=""
								 className="card" />
							<img src={`https://cloudflare-ipfs.com/ipfs/QmTfjqPkozhsrAa7DY9N97S1Nwe1AFuCvuFCySHswCEQzS/slime/72b.gif`} alt=""
								 className="card" />
							<img src={`https://cloudflare-ipfs.com/ipfs/QmTfjqPkozhsrAa7DY9N97S1Nwe1AFuCvuFCySHswCEQzS/relic/64b.gif`} alt=""
								 className="card" />
						</div>
						<img src={config.media.BIG_LOGO} alt="" className="brand-logo" />
					</div>
					<div className="actions">
						{
							!this.props.userAccount ?
								<AuxLayout>
									<small><i>Sign in using your WAX Cloud Wallet</i></small>
									<br />
									<button onClick={this.login}>Sign in</button>
									<button onClick={this.login} className="full">Create Account</button>
									<div className="continue">
										<Link to={"/shop"}>continue as guest</Link>
									</div>
								</AuxLayout>
								:
								<div className="continue">
									<Link to={"/shop"}>
										<button className="full">Trade Cards Now</button>
									</Link>
								</div>
						}
					</div>
				</div>
				<div id={"more"}>

					<div className={"card-details"}>
						<h1 className={"title"}>Topps’ Garbage Pail Kids cards on the Blockchain!</h1>
						<p>TOPPS and WAX have teamed up to bring the pop culture phenomenon, Garbage Pail Kids, to the blockchain! Collectors around
							the
							world can buy, gift and trade with confidence as each blockchain trading card is certified authentic, unique and can never
							be altered.</p>
					</div>
					<br />

					{/* EMAIL LIST */}

					<div className={"card-details"}>
						<h2 className={"title"}>Why Blockchain?</h2>
						<p>Blockchain technology allows collectors to enter a marketplace where they can purchase digital Garbage Pail Kids trading
							cards, propose and execute trades, showcase their inventory on social media, and search wishlists of other traders.</p>
					</div>
					<div className={"boxes"}>
						<div className={"box"} id={"product"}>
							<div className={"title"}>Detailed Product Specifications</div>
							<div className={"content"}>
								<div className={"entry"}>
									<span className={"type"}>Manufacturer:</span>
									<span className={"value"}>Topps</span>
								</div>
								<div className={"entry"}>
									<span className={"type"}>Card Name:</span>
									<span className={"value"}>Mad Mike</span>
								</div>
								<div className={"entry"}>
									<span className={"type"}>Date Minted:</span>
									<span className={"value"}>May 12, 2020</span>
								</div>
								<div className={"entry"}>
									<span className={"type"}>Card Rarity:</span>
									<span className={"value"}>Base</span>
								</div>
							</div>
						</div>
						<div className={"box"} id={"ownership"}>
							<div className={"title"}>Comprehensive Ownership Records</div>
							<div className={"content"}>
								<table>
									<thead>
									<tr>
										<th className={"owner"}>Owner</th>
										<th>Date</th>
										<th className={"tx"}>Trx</th>
									</tr>
									</thead>
									<tbody>
									<tr>
										<td className={"owner"}>Nick</td>
										<td>May 12, 2020</td>
										<td className={"tx"}>ae5712</td>
									</tr>
									<tr>
										<td className={"owner"}>John</td>
										<td>May 12, 2020</td>
										<td className={"tx"}>a71eb3</td>
									</tr>
									<tr>
										<td className={"owner"}>Frank</td>
										<td>May 13, 2020</td>
										<td className={"tx"}>f8ea23</td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div className={"digi-card"} style={{ position: "relative", width: "250px", margin: "25px auto" }}>
							<Card src={`https://cloudflare-ipfs.com/ipfs/QmSRti2HK95NXWYG3t3he7UK7hkgw8w9TdqPc6hi5euV1p/base/33a.jpg`}
								  back={`https://cloudflare-ipfs.com/ipfs/QmSRti2HK95NXWYG3t3he7UK7hkgw8w9TdqPc6hi5euV1p/back/33.jpg`} />
						</div>
						<div className={"box"} id={"history"}>
							<div className={"title"}>Complete Trading History</div>
							<div className={"content"}>
								<img src={Chart} alt={"trading history"} />
							</div>
						</div>
						<div className={"empty"}></div>
						<div className={"box"} id={"marketplace"}>
							<div className={"title"}>Instant Global Marketplace</div>
							<div className={"content"}>
								<img src={Globe} alt={"global market"} />
								<p>Instantly <b>trade</b> your cards to <b>anyone in the world.</b></p>
							</div>
						</div>
					</div>
					<br />
					<div className={"blockchain"}>
						<h2 className={"title"}>The Cards <small><i style={{ fontWeight: "300" }}>(and their rarities)</i></small></h2>
						<p style={{ fontWeight: "400", color: "a7a7a7" }}>Learn more about the rarities <a style={{color:"black"}} href={"/faq"}>here</a> </p>
						<p style={{ fontWeight: "400", color: "#a7a7a7" }}><small>Hover over or click on a card</small></p>
						<div className={"cards-rarity"}>

							<div className={"card-rarity"}>
								<Card src={`https://cloudflare-ipfs.com/ipfs/QmSRti2HK95NXWYG3t3he7UK7hkgw8w9TdqPc6hi5euV1p/base/1a.jpg`}
									  back={`https://cloudflare-ipfs.com/ipfs/QmSRti2HK95NXWYG3t3he7UK7hkgw8w9TdqPc6hi5euV1p/back/1.jpg`} />
								<div className={"name"}><b>Base</b> - Common</div>
							</div>
							
							<div className={"card-rarity"}>
								<Card src={`https://cloudflare-ipfs.com/ipfs/QmTfjqPkozhsrAa7DY9N97S1Nwe1AFuCvuFCySHswCEQzS/raw/42.jpg`}
									back={`https://cloudflare-ipfs.com/ipfs/QmTfjqPkozhsrAa7DY9N97S1Nwe1AFuCvuFCySHswCEQzS/back/42.jpg`}
									no3d={true} />
								<div className={"name"}><b>Raw</b> - Common</div>
							</div>

							<div className={"card-rarity"}>
								<Card src={`https://cloudflare-ipfs.com/ipfs/QmSRti2HK95NXWYG3t3he7UK7hkgw8w9TdqPc6hi5euV1p/prism/33a.gif`}
									  back={`https://cloudflare-ipfs.com/ipfs/QmSRti2HK95NXWYG3t3he7UK7hkgw8w9TdqPc6hi5euV1p/back/33.jpg`} />
								<div className={"name"}><b>Prism</b> - Uncommon</div>
							</div>

							<div className={"card-rarity"}>
								<Card src={`https://cloudflare-ipfs.com/ipfs/QmTfjqPkozhsrAa7DY9N97S1Nwe1AFuCvuFCySHswCEQzS/slime/49a.gif`}
									  back={`https://cloudflare-ipfs.com/ipfs/QmTfjqPkozhsrAa7DY9N97S1Nwe1AFuCvuFCySHswCEQzS/back/49.jpg`} />
								<div className={"name"}><b>Slime</b> - Uncommon</div>
							</div>

							<div className={"card-rarity"}>
								<Card src={`https://cloudflare-ipfs.com/ipfs/QmTfjqPkozhsrAa7DY9N97S1Nwe1AFuCvuFCySHswCEQzS/gum/61a.gif`}
									  back={`https://cloudflare-ipfs.com/ipfs/QmTfjqPkozhsrAa7DY9N97S1Nwe1AFuCvuFCySHswCEQzS/back/61.jpg`} />
								<div className={"name"}><b>Gum</b> - Uncommon</div>
							</div>

							<div className={"card-rarity"}>
								<Card src={`https://cloudflare-ipfs.com/ipfs/QmTfjqPkozhsrAa7DY9N97S1Nwe1AFuCvuFCySHswCEQzS/vhs/45a.gif`}
									back={`https://cloudflare-ipfs.com/ipfs/QmTfjqPkozhsrAa7DY9N97S1Nwe1AFuCvuFCySHswCEQzS/back/45.jpg`} />
								<div className={"name"}><b>VHS</b> - Uncommon</div>
							</div>

							<div className={"card-rarity"}>
								<Card src={`https://cloudflare-ipfs.com/ipfs/QmSRti2HK95NXWYG3t3he7UK7hkgw8w9TdqPc6hi5euV1p/sketch/30a.gif`}
									  back={`https://cloudflare-ipfs.com/ipfs/QmSRti2HK95NXWYG3t3he7UK7hkgw8w9TdqPc6hi5euV1p/back/30.jpg`} />
								<div className={"name"}><b>Sketch</b> - Rare</div>
							</div>

							<div className={"card-rarity"}>
								<Card src={`https://cloudflare-ipfs.com/ipfs/Qmbxify5MLarmoxTZTCDzSsUY6tRFwt1zbRBeW3gowUNJX/tigerborder/3b.gif`}
									  back={`https://cloudflare-ipfs.com/ipfs/Qmbxify5MLarmoxTZTCDzSsUY6tRFwt1zbRBeW3gowUNJX/back/11.jpg`} />
								<div className={"name"}><b>Tiger Stripe</b> - Rare</div>
							</div>

							<div className={"card-rarity"}>
								<Card src={`https://cloudflare-ipfs.com/ipfs/Qmbxify5MLarmoxTZTCDzSsUY6tRFwt1zbRBeW3gowUNJX/tigerscratch/11b.gif`}
									  back={`https://cloudflare-ipfs.com/ipfs/Qmbxify5MLarmoxTZTCDzSsUY6tRFwt1zbRBeW3gowUNJX/back/11.jpg`} />
								<div className={"name"}><b>Tiger Claw</b> - Super Rare</div>
							</div>
						
							<div className={"card-rarity"}>
								<Card src={`https://cloudflare-ipfs.com/ipfs/QmSRti2HK95NXWYG3t3he7UK7hkgw8w9TdqPc6hi5euV1p/collector/34a.gif`}
									  back={`https://cloudflare-ipfs.com/ipfs/QmSRti2HK95NXWYG3t3he7UK7hkgw8w9TdqPc6hi5euV1p/back/34.jpg`} />
								<div className={"name"}><b>Collector’s Edition</b> - Super Rare</div>
							</div>
						</div>
					</div>
					<footer>
						<div>
							&copy; WAX.io 2020, All rights reserved.
						</div>
						<div>
							<Link to={"/cookies#cookies"}>Cookies</Link>
							<Link to={"/tos#tos"}>Terms of Service</Link>
							<a href={"https://wax.io/privacy"} target="_blank">Privacy</a>
						</div>
					</footer>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		userAccount: state.userAccount
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onAuthUpdate: userAccount => dispatch({ type: actionTypes.UPDATE_AUTH_USERACCOUNT, userAccount: userAccount })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);