import React, {Component} from 'react';

import {connect} from "react-redux";

import * as API from "../../helpers/API";
import * as CompabilityAPI from '../../helpers/Compability';

import "./Trades.css";
import {Link} from "react-router-dom";
import WishlistView from '../../components/Inventory/WishlistView';
import FollowedList from '../../components/Social/FollowedList';

import TradeIcon from '../../assets/icons/trade.svg';
import ActionCard from '../../components/ActionCard';
import AuxLayout from '../../helpers/AuxLayout';
import Button from '../../components/Button/Button';
import config from '../../config/config';

class Trades extends Component {

    _mounted = false;

    state = { sentOffers: [], pendingOffers: [] };

    componentDidMount() {
        this._mounted = true;
        this.fetchTrades()
        let device = CompabilityAPI.getDeviceInformation();
        let interval = 3000;
        if (device.os === CompabilityAPI.OS.IOS || device.os === CompabilityAPI.OS.ANDROID) interval = 6000;
        if (device.os === CompabilityAPI.OS.MAC) interval = 4000;
        this.interval = setInterval(this.fetchTrades, interval);
    }
    

    fetchTrades = () => {
        if (this._mounted) {
            API.getSentOffers().then(res => {
                let { rows } = res;
                if (this._mounted) {
                    this.setState({sentOffers: rows});
                }
            }).catch(console.error)
            API.getPendingOffers().then(res => {
                let { rows } = res;
                if (this._mounted) {
                    this.setState({pendingOffers: rows});
                }
            }).catch(console.error)
        }
    }

    componentWillUnmount() {
        this._mounted = false;
        clearInterval(this.interval)
    }

    render() {
        let pendingOffers = this.state.pendingOffers.map(offer => (
            <Link key={offer.id} to={"/offer/" + offer.id}>
                <div className="trade">
                    <img src={config.media.LOGO} alt="" className="avatar" />
                    <b className="name">{ offer.owner }</b><br/>
                    <small className="action">sent you a trade offer (#{offer.id})</small>
                </div>
            </Link>
        ));
        let sentOffers = this.state.sentOffers.map(offer => (
            <Link key={offer.id} to={"/offer/" + offer.id}>
                <div className="trade">
            <img src={config.media.LOGO} alt="" className="avatar" />
                    <div><b className="name">{ offer.toaccount } </b></div><small className="action">You sent { offer.nfts.length + offer.fts.length } item{ offer.nfts.length !== 1 ? "s" : "" } in a trade offer. (#{offer.id})</small>
                </div>
            </Link>
        ));
        return (
            <div className="container" id="trades">
                <Link to={"/trade"}><Button className={"full"}>Send Offer</Button></Link>
                {
                    pendingOffers.length > 0 ?
                    <AuxLayout>
                        <div className={"title"} id="pending">
                            <b>Pending Offers</b>
                        </div>
                        <div className={"box"}>
                            <div className="trades-list">
                                { pendingOffers }
                            </div>
                        </div>
                    </AuxLayout>
                    : null
                }
                <div className={"title"} id="sent">
                    <b>Trade Offers You've Sent</b>
                </div>
                <div className={"box"}>
                    <div className="trades-list">
                        { sentOffers.length > 0 ? sentOffers : <Link to={"/trade"}><ActionCard className={"full small"} img={TradeIcon} text={"Send Offer"}/></Link> }
                    </div>
                </div>
                    <div className={"title"} id="wishlist">
                        <b>Wishlist</b>
                    </div>
                <div className={"box"}>
                    <div className="trades-list">
                        <WishlistView accountName={this.props.userAccount} />
                    </div>
                </div>
                    <div className={"title"} id="follow">
                        <b>Community Members You Follow</b>
                    </div>
                <div className={"box"}>
                    <div className="trades-list">
                        <FollowedList accountName={this.props.userAccount} link={"/p/:uid/trade"} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps  = state => {
    return {
        userAccount: state.userAccount
    }
}

export default connect(mapStateToProps)(Trades);