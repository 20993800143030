import React from 'react';
import { Link } from 'react-router-dom';

const faq = [
	{
		question: "What is the Topps x WAX partnership about?",
		answer: <span>For the first time ever, collectors can buy, gift, and trade Topps digital trading cards on the blockchain (the WAX Blockchain, to be specific) starting with the pop culture phenomenon <a
			href="https://www.topps.com/garbagepailkids" target="_blank">Garbage Pail Kids</a>.</span>
	},
	{
		question: "What can collectors do with their GPK Cards on WAX?",
		answer: <span>Collectors can trade their digital cards with others, showcase their inventory on social media, and gift their cards to a friend.</span>
	},
	{
		question: "How do I buy GPK Cards on WAX?",
		answer: <span>A: To purchase card packs: Log in to toppsgpk.io with your WAX Cloud Wallet, visit Shop, select your card packs, and complete the purchase. To purchase individual cards: visit a secondary marketplace like gpk.market or simplemarket.io. If you are paying with WAX Tokens on a secondary marketplace, you can read more about them <a
			href={"https://wax.io/blog/what-are-wax-tokens"}>here</a> .</span>
	},
	{
		question: "How do I trade GPK Cards to someone else on WAX?",
		answer: <span>You can trade or even gift your Topps GPK cards and packs to someone else by visiting <Link
			to={"/trades"}>toppsgpk.io/trades</Link> and clicking "Send Offer." Then select the community member that you wish to trade with. Select the item you wish to send from your inventory and if applicable, choose the items from your trading partner’s inventory that you wish to receive in return. Then click "Send Offer." Your pending offers appear on the Trade page.</span>
	},
	{
		question: "Where can I see the GPK Cards on WAX that I own?",
		answer: <span>All the Topps GPK Cards and Packs on WAX that you own appear in the "My Inventory" section which can be found at <Link
			to={"/inventory"}>toppsgpk.io/inventory</Link></span>
	},
	{
		question: "Where can I see all the GPK Cards that are available on WAX?",
		answer: <span>To view every Topps GPK Card that is available on the WAX Blockchain, you can browse the Catalog at <Link
			to="/catalog">toppsgpk.io/catalog</Link> </span>
	},
	{
		question: "How do I add GPK Cards to my wishlist?",
		answer: "You can add items to your wishlist, which other collectors can see and send trade offers for cards on your list. If your wishlist is empty, you will see a button to add items to it. By clicking that button, you’ll see a pop-up containing a list of all cards which you can click to add to your wishlist."
	},
	{
		question: "What are the rarities for GPK Cards on WAX?",
		answer: <span>

			{/* Series 2 */}
			
			
			<b>Series 2</b>
			
			<div>
				<div>
				The GPK Series 2 on WAX features the original 42 characters in “A” Name and “B” Name, along with some special additions to celebrate the 35th Anniversary of GPK Original Series 2.
				</div>
				<ul>
					<li><b>Base</b> - Standard static cards featuring “A”, “B”, and “C” names. Base cards are included in all packs</li>
					<li><b>Raw</b> -  Uncommon cards featuring raw Series 2 art, available in all packs</li>
					<li><b>Returning</b> - Uncommon cards with reimagined art of Original Series 2 characters, available in all packs</li>
					<li><b>Sketch</b> - Rare cards that feature an animated transition from original sketches to final art, available in all packs</li>
					<li><b>Slime</b> - Rare cards that feature an animated slime effect, Standard Pack exclusive</li>
					<li><b>Gum</b> -  Rare cards that feature an animated gum effect, Mega Pack exclusive</li>
					<li><b>VHS</b> -  Super rare cards that feature an animated VHS static effect, Ultimate Pack exclusive</li>
					<li><b>Collector's Edition</b> - Epic cards with special animations, available in all packs</li>
					<li><b>Original Art</b> - Epic cards featuring original art exclusively commissioned for GPK Series 2 on WAX, available in all packs</li>
				</ul>
			</div>

			{/* Exotic */}

			<b>GPK Goes Exotic” Series</b>
			<div>
				<div>
					The “GPK Goes Exotic” series features 15 characters, each with an “A” name and a “B” name. Cards come in 1 of 5 rarities:
				</div>
				<ul>
					<li><b>Base</b> - Standard static cards featuring both the “A” and “B” names of all 15 characters. Base Cards are included in every pack</li>
					<li><b>Prism</b> - Uncommon cards, these cards feature a prismatic sheen effect; “A” and “B” for all 15 characters</li>
					<li><b>Tiger</b> - Rare cards that feature an animated tiger border; “A” and “B” for all 15 characters</li>
					<li><b>Scratch</b> - Super rare cards that feature an animated tiger scratch effect; “A” and “B” for all 15 characters</li>
					<li><b>Collector's Edition</b> - Super rare cards with special animations; 3 characters available in “A” and “B” name</li>
				</ul>
			</div>

			 {/* Series 1 */}

			<b>Series 1</b>
			<div>
				The GPK on WAX card series features 41 characters, each with an “A” name and a “B” name. Cards come in 1 of 4 rarities:
            <ul>
					<li><b>Base</b> - Standard static cards featuring both the “A” and “B” names of all 41 characters from Original Series 1. Base Cards are included in every pack</li>
					<li><b>Prism</b> - Uncommon cards, these cards feature a prismatic sheen effect; “A” and “B” for all 41 characters</li>
					<li><b>Sketch</b> - Rare cards that feature an animated transition from original sketches to final art; “A” and “B” for all 41 characters</li>
					<li><b>Collector's Edition</b> - Super rare cards with special animations; 4 characters available in “A” and “B” name</li>
				</ul>
			</div>
		</span>
	},
	{
		question: "Where can I get support?",
		answer: <span>Have questions about GPK Cards on WAX? Visit <a href="https://t.me/wax_io" target="_blank">t.me/wax_io</a></span>
	},
	{
		question: "How does this partnership benefit buyers, traders, and collectors of digital trading cards?",
		answer: <span>By interacting with digital Garbage Pail Kids trading cards on the WAX Blockchain, anyone can see the details of the cards’ information and trading history. This enables everyone involved to make informed purchases, and trades, with anyone else in the world - instantly.
                    <br />
			<br />
                    The blockchain records every aspect of every card’s transaction and stores it forever. These blockchain records can’t be changed by anyone, and can be seen by everyone. Therefore, since the blockchain removes all uncertainty, there’s no need to blindly trust another trader.
                    <br />
			<br />
                    Data for Garbage Pail Kids cards on the blockchain includes:
                    <ul>
				<li><b>Detailed product specifications</b> for each card including the date it was created, its rarity, images of the card, proof of its authenticity, and more. With this information, <b>collectors know exactly which cards they are trading - no guesswork</b>, no need to trust or verify other traders.</li>
				<li><b>Comprehensive ownership records</b> that show who traded the card and when. Since everyone can see its full ownership history, <b>no one can ever fake ownership of a card.</b></li>
				<li><b>Complete trade history</b></li>
			</ul>
		</span>
	},
	{
		question: "Why did Topps choose the WAX Blockchain?",
		answer: "Topps chose WAX because the WAX Blockchain is a safe and convenient way to create, buy, sell, and trade virtual items to anyone, anywhere in the world. It has facilitated the trade of more than $100 million digital items since 2017."
	},
	{
		question: "What are Golden Cards?",
		answer: <span>Golden Cards are cards sent to winners of special promotions. Follow <a href={"https://twitter.com/wax_io"}>WAX</a>, <a
			href={"https://twitter.com/Topps"}>Topps</a>, and <a
				href={"https://twitter.com/ToppsDigital"}>Topps Digital</a> on Twitter to be notified of these promotions so you can enter to win!</span>
	},
	{
		question: "What is WAX?",
		answer: <span>WAX is a safe and convenient way to create, buy, sell, and trade virtual items - to anyone, anywhere in the world. Hundreds of millions of people around the globe already trade digital and physical items. But the problems that plague the digital goods and trading community have stunted its growth far beneath its full potential. WAX is creating a full suite of blockchain-based tools that allows anyone to trade digital or even physical items instantly and securely, to anyone, anywhere. Participants of the Worldwide Asset eXchange gain access to a global community of collectors and traders, buyers and sellers, creators and gamers, merchants, dApp creators, and game developers. Learn more at <a
			href="https://wax.io/" target="_blank">https://wax.io </a></span>
	},
	{
		question: "What is Topps?",
		answer: <span>Founded in 1938, The Topps Company, Inc. is the preeminent creator and brand marketer of physical and digital sports cards, entertainment cards and collectibles, and distinctive confectionery products. Topps' leading sports and entertainment products include Major League Baseball, Major League Soccer, UEFA Champions League, Bundesliga, Star Wars, WWE, UFC, Wacky Packages, Garbage Pail Kids, Mars Attacks and other trading cards, sticker album collections and collectibles. Topps’ digital trading card apps portfolio, which has been a hit with millions of fans around the world, currently includes Topps® BUNT®, TOPPS® KICK®, Topps® Star Wars℠: Card Trader, Topps® WWE Slam®, Topps® NHL SKATE™, The Walking Dead: Card Trader, Marvel Collect! by Topps and Disney Collect! by Topps! Topps’ confectionery brands include Ring Pop®, Push Pop®, Baby Bottle Pop®, Juicy Drop ® Pop, and Bazooka® bubble gum. Topps was acquired by Michael Eisner’s Tornante Company and Madison Dearborn Partners in October 2007. For additional information, visit <a
			href="https://topps.com/" target="_blank">Topps.com</a> and <a href="https://candymania.com/" target="_blank">Candymania.com</a>.</span>
	},
];

export default faq