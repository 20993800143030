import React, { Component } from 'react';

import Swal from 'sweetalert2'
import "./TradeOffer.css";

import * as API from "../../helpers/API";

import AuxLayout from "../../helpers/AuxLayout";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";

import Packs from "../../helpers/packs";
import Pagination from '../../components/Pagination';
import ItemView from '../../components/ItemView';

import Button from "../../components/Button/Button";

class TradeOffer extends Component {
  _mounted = false;
  state = {
    offer: undefined,
    items: [],
    packs: {},
    conditions: [],
    redirect: null,

    loading: false
  };

  componentDidMount() {
    this._mounted = true;
    // GetTradeID
    this.fetchTrade(this.props.match.params.id);
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  fetchTrade = tid => {
    // Load Trade
    API.getTrade(tid).then(offer => {
      if (this._mounted) {
        this.setState({ offer: offer });
        if (offer != null) {
          this.loadInventories(offer.owner);
          if (offer.toaccount && offer.toaccount.length > 0) {
            this.loadInventories(offer.toaccount);
          }
          this.loadConditions(tid);
        }
      }
    }).catch(console.error)
  }


  loadConditions = tid => {
    API.getConditionsForTrade(tid).then(res => {
      let conds = res.rows;
      conds = conds.filter(cond => cond.boxid === 1);
      this.setState({ conditions: conds });
    });
  }


  loadInventories = accountName => {
    API.getInventory(accountName).then(inv => {
      this.setState({ items: [...this.state.items, ...inv] })
    }).catch(console.error)
    API.getTradeInventory(accountName).then(res => {
      let inv = res.rows;
      this.setState({ items: [...this.state.items, ...inv] })
    }).catch(console.error)
    API.getPacksOwned(accountName).then(({ rows }) => {
      let packs = [];
      let tokens = rows;
      for (let i = 0; i < Packs.length; i++) {
        let pack = Packs[i];
        let token = tokens.filter(tk => tk.token === pack.token);
        if (token && token.length > 0) {
          let packToken = token[0];
          let amount = parseInt(packToken.balance) + parseInt(packToken.wet_balance);

          pack.balance = parseInt(packToken.balance);
          pack.wet_balance = parseInt(packToken.wet_balance);

          pack.amount = amount;
          packs.push(JSON.parse(JSON.stringify(pack)))
        }
      }
      let packsState = this.state.packs;
      packsState[accountName] = packs;
      this.setState({ packs: packsState });
    }).catch(console.error)
  }

  acceptOffer = async (nfts_to_send, packs_to_send, requiredFTs) => {
    this.setState({ loading: true });
    try {
      await API.acceptOffer(nfts_to_send, packs_to_send, this.state.offer.id, 1, "Accepting this trade offer", requiredFTs)
      await Swal.fire({
        title: 'Offer Accepted!',
        icon: 'success',
        confirmButtonText: 'Ok'
      });
      this.setState({ redirect: "/trades" })
    } catch (err) {
      console.error(err);
      await Swal.fire({
        title: 'Accepting failed!',
        text: err.toString(),
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
    this.setState({ loading: false });
  }

  denyOffer = async tid => {
    this.setState({ loading: true });
    try {
      await API.cancelOffer(this.state.offer.id);
      await Swal.fire({
        title: 'Offer Declined!',
        icon: 'success',
        confirmButtonText: 'Ok'
      });
      this.setState({ redirect: "/trades" })
    } catch (err) {
      console.error(err);
      await Swal.fire({
        title: 'Denying failed!',
        text: err.toString(),
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
    this.setState({ loading: false });
  }

  cancelOffer = async () => {
    this.setState({ loading: true });
    try {
      let nftsToWithdraw = [];
      await API.cancelOffer(this.state.offer.id, nftsToWithdraw)
      await Swal.fire({
        title: 'Offer Cancelled!',
        icon: 'success',
        confirmButtonText: 'Ok'
      });
      this.setState({ redirect: "/trades" })
    } catch (err) {
      console.error(err);
      await Swal.fire({
        title: 'Cancellation failed!',
        text: err.toString(),
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
    this.setState({ loading: true });
  }

  render() {
    let error = [];
    if (this.state.redirect) return <Redirect to={this.state.redirect} />;
    if (this.state.offer === null) return <Redirect to={"/trades"} />;
    if (!this.state.offer) return "Loading trade offer";
    let accountName = global.wax.userAccount;
    let cards_to_send = this.state.items.filter(nft => this.state.offer.nfts.includes(nft.id));
    let packs_to_send = [];
    // handle senders packs
    let sendersPacks = this.state.packs[this.state.offer.owner];
    if (sendersPacks) {
      for (let i = 0; i < sendersPacks.length; i++) {
        let pack = sendersPacks[i];
        for (let j = 0; j < this.state.offer.fts.length; j++) {
          let ft = this.state.offer.fts[j];
          let [amount, token] = ft.quantity.split(" ");
          amount = parseInt(amount);
          if (token === pack.token) {
            for (let i = 0; i < amount; i++) {
              packs_to_send.push(pack)
            }
          }
        }

      }
    }
    // handle conditions on receiver side
    let cards_to_receive = [];

    let conds = this.state.conditions;

    let packs_to_receive = [];
    for (let i = 0; i < conds.length; i++) {

      let nfts = this.state.items;
      let packs = this.state.packs[this.state.offer.toaccount];
      if (!packs) packs = [];

      let { aconditions } = conds[i];
      for (let j = 0; j < aconditions.length; j++) {
        let { key, operation, value } = aconditions[j];
        if (operation === "=") {
          switch (key) {
            case "quantity":
              let [amount, token] = value.split(" ");
              packs = packs.filter(pack => {
                return pack.token == token;
              }).map(pack => {
                pack.amount = parseFloat(amount);
                return pack;
              })
              break;
            case "assettype":
              if (value !== "0") {
                nfts = [];
              }
              if (value !== "2") {
                packs = [];
              }
              break;
            default:
              nfts = nfts.filter(nft => {
                return nft[key] && nft[key] == value;
              });
              packs = packs.filter(pack => {
                return pack[key] && pack[key] == value;
              });
              break;
          }
        } else if (operation === "!=") {
          nfts = [];
          packs = [];
        }
      }
      if (nfts && nfts.length >= 1) cards_to_receive.push(nfts[0]);
      if (packs && packs.length >= 1) packs_to_receive.push(packs[0])
    }
    let requiredFTs = packs_to_send.map(pack => `1 ${pack.token}`)
    requiredFTs = [...requiredFTs, ...packs_to_receive.map(pack => `1 ${pack.token}`)];

    let items_to_send = cards_to_send.map((nft, i) => <ItemView key={i} name={nft.mdata.name} image={nft.mdata.img} />);
    items_to_send = [
      ...items_to_send,
      ...packs_to_send.map((ft, i) => <ItemView key={i} name={`${ft.series} ${ft.edition}`} image={ft.img} />)
    ]
    let items_to_receive = cards_to_receive.map((nft, i) => <ItemView key={i} name={nft.mdata.name}
                                                                      image={nft.mdata.img} />)
    let packsAdded = [];
    packs_to_receive.forEach(pack => {
      for (let i = 0; i < pack.amount; i++) {
        packsAdded.push(<ItemView key={i} name={`${pack.series} ${pack.edition}`} image={pack.img} />)
      }
    })
    items_to_receive = [
      ...items_to_receive,
      ...packsAdded
    ]

    return (
      <div className="container" id="tradeoffer">
        <div className="box">
          <div className="trader">
            <div className="info">
              <b className="name">{this.state.offer.owner} {this.state.offer.owner === accountName ?
                <small><i>You</i></small> : null}</b><br />
              <small
                className="action">sends {this.state.offer.nfts.length + this.state.offer.fts.length} item{(this.state.offer.nfts.length + this.state.offer.fts.length) !== 1 ? "s" : ""}</small>
            </div>
          </div>
          <Pagination wrapper={items => <div className="items">{items}</div>} items={items_to_send}></Pagination>

        </div>
        <div className="box">
          <div className="trader">
            <div className="info">
              <Link to={"/p/" + this.state.offer.toaccount}><b
                className="name">{this.state.offer.toaccount} {this.state.offer.toaccount === accountName ?
                <small><i>You</i></small> : null}</b></Link><br />
              <small
                className="action">sends {items_to_receive.length + packsAdded.length} item{(items_to_receive.length + packsAdded.length) > 1 ? "s" : ""}</small>
            </div>
          </div>
          <Pagination wrapper={items => <div className="items">{items}</div>} items={items_to_receive}></Pagination>
        </div>
        <div className="actions">
          {
            this.state.offer.toaccount === accountName ? (
              <AuxLayout>
                <Button loading={this.state.loading ? 1 : 0}
                        onClick={() => this.acceptOffer(cards_to_receive, packs_to_receive, requiredFTs)}>Accept
                  Offer</Button>
                <Button loading={this.state.loading ? 1 : 0} onClick={this.denyOffer}>Deny Offer</Button>
              </AuxLayout>
            ) : null
          }
          {
            this.state.offer.owner === accountName ?
              (<Button loading={this.state.loading ? 1 : 0} onClick={this.cancelOffer}>Cancel Offer</Button>)
              : null
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userAccount: state.userAccount
  }
}

export default connect(mapStateToProps)(withRouter(TradeOffer));