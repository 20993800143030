import Config from '../config/config'

const packs = [

	// Series 2
	{
		series: "Series 2",
		edition: "Standard Pack",
		img: "QmWkB8bBEoHai7Li5jHdUbavFKxnfQmVvGJj5ytyWpNbUt/1.jpg",
		cardsInPack: 8,
		token: Config.packs.TOKEN_NAMES.gpktwoeight,
		type: Config.packs.PACK_TYPES.gpktwoeight,
		author: Config.contracts.TOKEN,
		fseries: "series2",
	},
	{
		series: "Series 2",
		edition: "Mega Pack",
		img: "QmWkB8bBEoHai7Li5jHdUbavFKxnfQmVvGJj5ytyWpNbUt/2.jpg",
		cardsInPack: 25,
		token: Config.packs.TOKEN_NAMES.gpktwo25,
		type: Config.packs.PACK_TYPES.gpktwo25,
		author: Config.contracts.TOKEN,
		fseries: "series2",
	},
	{
		series: "Series 2",
		edition: "Ultimate Pack",
		img: "QmWkB8bBEoHai7Li5jHdUbavFKxnfQmVvGJj5ytyWpNbUt/3.jpg",
		cardsInPack: 55,
		token: Config.packs.TOKEN_NAMES.gpktwo55,
		type: Config.packs.PACK_TYPES.gpktwo55,
		author: Config.contracts.TOKEN,
		fseries: "series2",
	},



	// Exotic
	{
		series: "GPK Goes Exotic",
		edition: "Standard Pack",
		img: "QmZBXd6CWeSYc6ZxDcRPC54dwZv4NeSBoRahY8bYDdYPui",
		cardsInPack: 5,
		token: Config.packs.TOKEN_NAMES.exotic5,
		type: Config.packs.PACK_TYPES.exotic5,
		author: Config.contracts.TOKEN,
		fseries: "exotic",
	},
	{
		series: "GPK Goes Exotic",
		edition: "Mega Pack",
		img: "QmNjTxU8DBN7us9cUt5y9Uju5b7KEQa4Uwj7FhsuAZ79HQ",
		cardsInPack: 25,
		token: Config.packs.TOKEN_NAMES.exotic25,
		type: Config.packs.PACK_TYPES.exotic25,
		author: Config.contracts.TOKEN,
		fseries: "exotic",
	},

	// Series 1
	{
		series: "Series 1",
		edition: "Standard Pack",
		img: "Qmb8aENU2CemXz4daoW26eqFviSuYvV2NiA296GzDaKKs3/standard.jpg",
		cardsInPack: 5,
		token: Config.packs.TOKEN_NAMES.five,
		type: Config.packs.PACK_TYPES.five,
		author: Config.contracts.TOKEN,
		fseries: "series1",
	},
	{
		series: "Series 1",
		edition: "Mega Pack",
		img: "Qmb8aENU2CemXz4daoW26eqFviSuYvV2NiA296GzDaKKs3/mega.jpeg",
		cardsInPack: 30,
		token: Config.packs.TOKEN_NAMES.thirty,
		type: Config.packs.PACK_TYPES.thirty,
		author: Config.contracts.TOKEN,
		fseries: "series1",
	}
];
export default JSON.parse(JSON.stringify(packs));