import defSeries1 from "./series/series_1";
import defSeries2 from "./series/series_2";
import defExotic from "./series/exotic";

const getSeries = (series) => {
	if (series === "series1") return defSeries1;
	if (series === "exotic") return defExotic;
	if (series === "series2") return defSeries2;
	return [];
}

export default {
	getSeries,
}