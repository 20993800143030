import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Card from '../components/Card/Card'
import replaceIpfsHash from '../helpers/ipfsDebugger';

export default class CardView extends Component {

    render() {
        let { name, img, back, backimg } = this.props;
        img = replaceIpfsHash(img);
        if (img) img = img.includes("http") ? img : ("https://cloudflare-ipfs.com/ipfs/" + img);
        if (back) back = back.includes("http") ? back : ("https://cloudflare-ipfs.com/ipfs/" + back);
        if (backimg) back = backimg.includes("http") ? backimg : ("https://cloudflare-ipfs.com/ipfs/" + backimg)
        return (
            <div className="CardView" onClick={this.props.onClick}>
                <Card noFlip={true} src={img} back={back } className={"preview"} no3d={this.props.no3d} />
            </div>
        )
    }
}