import React, {Component} from 'react';
import Swal from 'sweetalert2'

import "./CreateOffer.css";

import * as API from "../../helpers/API";

import plus from "../../assets/icons/plus.svg";
import InventorySelector from "../../components/Inventory/InventorySelector";
import {withRouter, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import Button from '../../components/Button/Button'

import Packs from '../../helpers/packs';
import ItemView from '../ItemView';

class CreateOffer extends Component {

    _mounted = false;

    state = { 
        memo: "", 
        selectingItems: false, 
        selectingSide: 0, 
        own: {
            uid: this.props.userAccount, 
            cards: [], 
            selected: [],
            packs: []
        }, 
        partner: {
            uid: this.props.match.params.uid, 
            cards: [], 
            selected: [],
            packs: []
        }, 
        sent: false,

        loadingSendOffer: false
    };

    componentDidMount() {
        this._mounted = true;
        this.loadOwnInventory();
        this.loadPartnerinventory();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    loadOwnInventory = () => {
        API.getInventory(this.props.userAccount).then(inv => {
            if (this._mounted) {
                let own = this.state.own;
                own.cards = [...own.cards, ...inv];
                this.setState({ own: own })
            }
        }).catch(console.error)
        API.getTradeInventory(this.props.userAccount).then(res => {
            if (this._mounted) {
                let inv = res.rows;
                let own = this.state.own;
                own.cards = [...own.cards, ...inv];
                this.setState({ own: own })
            }
        }).catch(console.error)
        API.getPacksOwned(this.props.userAccount).then(({rows}) => {
            let packs = [];
            let tokens = rows;
            for (let i = 0; i<Packs.length; i++) {
                let pack = Packs[i];
                let token = tokens.filter(tk => tk.token === pack.token);
                if (token && token.length > 0) {
                    let packToken = token[0];
                    let amount = parseInt(packToken.balance) + parseInt(packToken.wet_balance);
                    
                    pack.balance = parseInt(packToken.balance);
                    pack.wet_balance = parseInt(packToken.wet_balance);
                    pack.inproposal = packToken.inproposal;
                    
                    pack.amount = amount;
                    packs.push(JSON.parse(JSON.stringify(pack)))
                }
            }
            let own = this.state.own;
            own.packs = JSON.parse(JSON.stringify(packs));
            if (this._mounted) {
                this.setState({ own });
            }
        }).catch(console.error)
    }

    loadPartnerinventory = () => {
        API.getInventory(this.state.partner.uid).then(inv => {
            if (this._mounted) {
                inv = inv.filter(nft => nft);
                let partner = this.state.partner;
                partner.cards = [...partner.cards, ...inv];
                if (this._mounted) {
                    this.setState({ partner: partner })
                }
            }
        }).catch(console.error)
        API.getTradeInventory(this.state.partner.uid).then(res => {
            if (this._mounted) {
                let inv = res.rows;
                let partner = this.state.partner;
                partner.cards = [...partner.cards, ...inv];
                if (this._mounted) {
                    this.setState({ partner: partner })
                }
            }
        }).catch(console.error)
        API.getPacksOwned(this.state.partner.uid).then(({rows}) => {
            let packs = [];
            let tokens = rows;
            for (let i = 0; i<Packs.length; i++) {
                let pack = Packs[i];
                let token = tokens.filter(tk => tk.token === pack.token);
                if (token && token.length > 0) {
                    let packToken = token[0];
                    let amount = parseInt(packToken.balance) + parseInt(packToken.wet_balance);
                    
                    pack.amount = amount;
                    packs.push(JSON.parse(JSON.stringify(pack)))
                }
            }
            let partner = this.state.partner;
            partner.packs = JSON.parse(JSON.stringify(packs));
            if (this._mounted) {
                this.setState({ partner });
            }
        }).catch(console.error)
    }

    updateSelected = selected => {
        if (this.state.selectingSide === 0) {
            let newOwn = this.state.own;
            newOwn.selected = selected;
            if (this._mounted) {
                this.setState({ own: newOwn })
            }
        }
        if (this.state.selectingSide === 1) {
            let newPartner = this.state.partner;
            newPartner.selected = selected;
            if (this._mounted) {
                this.setState({ partner: newPartner })
            }
        }
    }

    updateSelectedPacks = packs => {
        if (this.state.selectingSide === 0) {
            let newOwn = this.state.own;
            newOwn.packs = packs;
            if (this._mounted) {
                this.setState({ own: newOwn })
            }
        }
        if (this.state.selectingSide === 1) {
            let newPartner = this.state.partner;
            newPartner.packs = packs;
            if (this._mounted) {
                this.setState({ partner: newPartner })
            }
        }
    }

    removeItem = (side, item) => {
        if (side === 0) {
            let newOwn = this.state.own;
            newOwn.selected = newOwn.selected.filter(itemid => itemid !== item);
            if (this._mounted) {
                this.setState({ own: newOwn })
            }
        }
        if (side === 1) {
            let newPartner = this.state.partner;
            newPartner.selected = newPartner.selected.filter(itemid => itemid !== item);
            if (this._mounted) {
                this.setState({ partner: newPartner })
            }
        }
    }
    

    addPack = (side, pack, pos) => {
        if (side === 0) {
            let newOwn = this.state.own;
            let newPacks = newOwn.packs.slice();
            for (let i = 0; i<newPacks.length; i++) {
                if (newPacks[i].token === pack.token) {
                    if (newPacks[i].selected) {
                        newPacks[i].selected.push(pos);
                    } else {
                        newPacks[i].selected = [pos];
                    }
                }
            }
            newOwn.packs = newPacks;
            if (this._mounted) {
                this.setState({ own: newOwn })
            }
        }
        if (side === 1) {
            let newPartner = this.state.patner;
            let newPacks = newPartner.packs.slice();
            for (let i = 0; i<newPacks.length; i++) {
                if (newPacks[i].token === pack.token) {
                    if (newPacks[i].selected) {
                        newPacks[i].selected.push(pos);
                    } else {
                        newPacks[i].selected = [pos];
                    }
                }
            }
            newPartner.packs = newPacks;
            if (this._mounted) {
                this.setState({ partner: newPartner })
            }
        }
    }

    removePack = (side, pack, pos) => {
        
        if (side === 0) {
            let newOwn = this.state.own;
            let newPacks = JSON.parse(JSON.stringify(newOwn.packs));
            for (let i = 0; i<newPacks.length; i++) {
                if (newPacks[i].token === pack.token) {
                    if (newPacks[i].selected) {
                        newPacks[i].selected.splice(newPacks[i].selected.indexOf(pos), 1);
                    } else {
                        newPacks[i].selected = [];
                    }
                }
            }
            newOwn.packs = newPacks;
            if (this._mounted) {
                this.setState({ own: newOwn })
            }
        }
        if (side === 1) {
            let newPartner = this.state.partner;
            let newPacks = newPartner.packs.slice();
            for (let i = 0; i<newPacks.length; i++) {
                if (newPacks[i].token === pack.token) {
                    if (newPacks[i].selected) {
                        newPacks[i].selected.splice(newPacks[i].selected.indexOf(pos), 1);
                    } else {
                        newPacks[i].selected = [];
                    }
                }
            }
            newPartner.packs = newPacks;
            if (this._mounted) {
                this.setState({ partner: newPartner })
            }
        }
    }

    sendOffer = async () => {
        if (this._mounted) {
            this.setState({ loadingSendOffer: true });
        }
        try {

            let packsToSend = this.state.own.packs
            .filter(pack => pack.selected && pack.selected.length > 0)
            .map(pack => {
                let amount = pack.selected.length;
                let owned = pack.amount;
                let wet_owned = pack.wet_balance;
                let depoAmountNeeded = 0;
                if (owned >= amount) depoAmountNeeded = amount;
                else depoAmountNeeded = amount - owned;
                return {
                    quantity: `${amount} ${pack.token}`,
                    deposit: depoAmountNeeded > 0 ? `${depoAmountNeeded} ${pack.token}` : undefined
                }
            });
            let receipt = await API.offerTrade(
                this.state.partner.uid, 
                this.state.own.selected, 
                packsToSend,
                this.state.partner.cards.filter(nft => this.state.partner.selected.includes(nft)), 
                this.state.partner.packs.map(pack => pack.selected && pack.selected.length > 0 ? `${pack.selected.length} ${pack.token}`: null).filter(ft => ft != null),
                this.state.memo
            );
            if (this._mounted) {
                this.setState({sent: true})
            }
            await Swal.fire({
                title: 'Offer Sent!',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
        } catch(err) {
            console.error(err)
            await Swal.fire({
                title: 'Sending Offer failed!',
                text: err.toString(),
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
        if (this._mounted) {
            this.setState({ loadingSendOffer: false });
        }
    }

    render() {
        if (this.state.sent) return <Redirect to={"/trades"} />
        let trader = {};
        if (this.state.selectingSide === 0) trader = this.state.own;
        if (this.state.selectingSide === 1) trader = this.state.partner;


        let items_to_send = [];

        let lastKey = 0;

        // Display Packs being sent
        let ownPacks = JSON.parse(JSON.stringify(this.state.own.packs));
        for (let i = 0; i <ownPacks.length; i++) {
            let pack = ownPacks[i];
            for (let j = 0; j<pack.amount; j++) {
                let name = `${pack.series} ${pack.edition}`, image = pack.img;
                if (image && !image.includes("http")) image = "https://cloudflare-ipfs.com/ipfs/" + image;
                let isInPack = pack.selected && pack.selected.includes(j);
                if (isInPack) items_to_send.push(<ItemView key={lastKey++} name={name} image={image} removalAction={this.removePack.bind(this, 0, pack, j)} />);
            }
        }

        // Display Cards being sent
        let ownCards = this.state.own.cards.filter(item => this.state.own.selected.includes(item));
        for (let i = 0; i<ownCards.length; i++) {
            let item = ownCards[i];
            let name, image;
    
            try {item.mdata = JSON.parse(item.mdata)} catch (err) {}
            try {item.idata = JSON.parse(item.idata)} catch (err) {}
            if (!item.idata) item.idata = {};
            item.mdata = {
                ...item.idata,
                ...item.mdata
            }
            if (item.mdata.name) name = item.mdata.name;
            if (item.mdata.name) name = item.mdata.name;
            if (item.mdata.img) image = item.mdata.img;
            if (item.mdata.image) image = item.mdata.image;
            if (item.mdata.img) image = item.mdata.img;
            if (item.mdata.image) image = item.mdata.image;
            if (image && !image.includes("http")) image = "https://cloudflare-ipfs.com/ipfs/" + image;

            items_to_send.push(<ItemView key={lastKey++} name={name} image={image} removalAction={this.removeItem.bind(this, 0, item)} />)
        }



        
        let items_to_receive = [];

        // Display Cards receiving
        let partnerPacks = JSON.parse(JSON.stringify(this.state.partner.packs));
        for (let i = 0; i <partnerPacks.length; i++) {
            let pack = partnerPacks[i];
            for (let j = 0; j<pack.amount; j++) {
                let name = `${pack.series} ${pack.edition}`, image = pack.img;
                if (image && !image.includes("http")) image = "https://cloudflare-ipfs.com/ipfs/" + image;
                let isInPack = pack.selected && pack.selected.includes(j);
                if (isInPack) items_to_receive.push(<ItemView key={lastKey++} name={name} image={image} removalAction={this.removePack.bind(this, 1, pack, j)} />);
            }
        }

        // Display Cards receiving
        let partnerCards = this.state.partner.cards.filter(item => this.state.partner.selected.includes(item));
        for (let i = 0; i<partnerCards.length; i++) {
            let item = partnerCards[i];
            let name, image;
    
            try {item.mdata = JSON.parse(item.mdata)} catch (err) {}
            try {item.idata = JSON.parse(item.idata)} catch (err) {}
            if (!item.idata) item.idata = {};
            item.mdata = {
                ...item.idata,
                ...item.mdata
            }
            if (item.mdata.name) name = item.mdata.name;
            if (item.mdata.img) image = item.mdata.img;
            if (item.mdata.image) image = item.mdata.image;
            if (image && !image.includes("http")) image = "https://cloudflare-ipfs.com/ipfs/" + image;
            items_to_receive.push(<ItemView key={lastKey++} name={name} image={image} removalAction={this.removeItem.bind(this, 0, item)} />)
        }
        return (
            <div className="" id="createoffer">
                <div className="box">
                    <div className="trader">
                            <div className="info">
                                <b className="name"><small>Items I want to send</small></b><br/>
                                <small className="action">{ this.state.own.selected.length } items selected</small>
                            </div>
                            <button onClick={() => this.setState({ selectingItems: true, selectingSide: 0 })} className="secondary">Select</button>
                    </div>
                    <div className="items">
                        <div className="addcard" onClick={() => this.setState({ selectingItems: true, selectingSide: 0 })}><img src={plus} alt="" /></div>
                        { items_to_send }
                    </div>
                </div>
                <div className="box">
                    <div className="trader">
                            <div className="info">
                                <b className="name"><small>Items I want from</small> { this.state.partner.uid }</b><br/>
                                <small className="action">{ this.state.partner.selected.length } items selected</small>
                            </div>
                            <button onClick={() => this.setState({ selectingItems: true, selectingSide: 1 })} className="secondary">Select</button>
                    </div>
                    <div className="items">
                        <div className="addcard" onClick={() => this.setState({ selectingItems: true, selectingSide: 1 })}><img src={plus} alt="" /></div>
                        { items_to_receive }
                    </div>
                </div>
                <div className="trade-actions">
                    <small>Message to {this.state.partner.uid}: </small>
                    <input type={"text"} className={"memo-input"} name={"memo"} placeholder={"Write a memo"} onInput={e => this.setState({memo: e.target.value})} />
                    <Button loading={this.state.loadingSendOffer ? 1 : 0} onClick={this.sendOffer}>Send Offer</Button>
                </div>

                <InventorySelector
                    uid={trader.uid}
                    cards={trader.cards}
                    packs={JSON.parse(JSON.stringify(trader.packs))}
                    selected={trader.selected}
                    updateSelection={this.updateSelected}
                    updatePacks={this.updateSelectedPacks}
                    active={this.state.selectingItems}
                    close={() => this.setState({selectingItems: false})}/>

            </div>
    );
    }
}

const mapStateToProps  = state => {
    return {
        userAccount: state.userAccount
    }
}

export default connect(mapStateToProps)(withRouter(CreateOffer));