import React, {Component} from 'react';
import Popup from "../Popup/Popup";
import CardSelector from "./CardSelector";

import TabSelector from '../TabSelector';
import PackSelector from './PackSelector';
import config from '../../config/config';

class InventorySelector extends Component {

    state = {
        tab: "Cards"
    }

    toggleSelection = (id) => {
        let newSelected = this.props.selected;
        if (newSelected.includes(id)) {
            newSelected.splice(newSelected.indexOf(id), 1);
        } else {
            newSelected.push(id);
        }
        this.props.updateSelection(newSelected);
    }
    
    addPack = (pack, pos) => {
        let newPacks = this.props.packs.slice();
        for (let i = 0; i<newPacks.length; i++) {
            if (newPacks[i].token === pack.token) {
                if (newPacks[i].selected) {
                    newPacks[i].selected.push(pos);
                } else {
                    newPacks[i].selected = [pos];
                }
            }
        }
        this.props.updatePacks(newPacks);
    }

    removePack = (pack, pos) => {
        let newPacks = this.props.packs.slice();
        for (let i = 0; i<newPacks.length; i++) {
            if (newPacks[i].token === pack.token) {
                if (newPacks[i].selected) {
                    newPacks[i].selected.splice(newPacks[i].selected.indexOf(pos), 1);
                } else {
                    newPacks[i].selected = [];
                }
            }
        }
        this.props.updatePacks(newPacks);
    }

    setTab = tab => {
        this.setState({tab})
    }

    render() {
        return (
            <Popup active={this.props.active} close={this.props.close}>
                <div className="trader">
                    <img src={config.media.LOGO} alt="" className="avatar" />
                    <div className="info">
                        <b className="name">{ this.props.uid }</b><br/>
                        <small className="action">{ this.props.selected.length } items selected</small>
                    </div>
                    <button onClick={this.props.close}>Select</button>
                </div>
                <TabSelector defaultTab={this.state.tab} tabs={["Cards", "Packs"]} onChange={this.setTab}/>
                {
                    this.state.tab === "Cards" ?
                    <CardSelector items={this.props.cards} toggleSelection={this.toggleSelection} selected={this.props.selected}/>
                    : null
                }
                {
                    this.state.tab === "Packs" ?
                    <PackSelector items={this.props.packs} addPack={this.addPack}  removePack={this.removePack} />
                    : null
                }
            </Popup>
        );
    }
}

export default InventorySelector;