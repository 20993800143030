const IPFS_HASH = "Qmbxify5MLarmoxTZTCDzSsUY6tRFwt1zbRBeW3gowUNJX";
const names = [
    {
      "variant": "1a",
      "name": "Joe Chaotic"
    },
    {
      "variant": "2a",
      "name": "Jettin' James"
    },
    {
      "variant": "3a",
      "name": "Jeff Joke"
    },
    {
      "variant": "4a",
      "name": "Cool Cat Carole"
    },
    {
      "variant": "5a",
      "name": "Disturbing Doc"
    },
    {
      "variant": "6a",
      "name": "Humiliated Howard"
    },
    {
      "variant": "7a",
      "name": "Roasted Rick"
    },
    {
      "variant": "8a",
      "name": "Allen Assassin"
    },
    {
      "variant": "9a",
      "name": "Lenient Lauren"
    },
    {
      "variant": "10a",
      "name": "Jazzy Joe"
    },
    {
      "variant": "11a",
      "name": "Ortho-John-Tist"
    },
    {
      "variant": "12a",
      "name": "Political Junkie Joshua"
    },
    {
      "variant": "13a",
      "name": "Savage Saff"
    },
    {
      "variant": "14a",
      "name": "Nutty Nicolas"
    },
    {
      "variant": "15a",
      "name": "Ty Grrr"
    },
    {
      "variant": "1b",
      "name": "Schmo Exotic"
    },
    {
      "variant": "2b",
      "name": "Gangsta' Garretson"
    },
    {
      "variant": "3b",
      "name": "Lowe Point"
    },
    {
      "variant": "4b",
      "name": "Batty Baskin"
    },
    {
      "variant": "5b",
      "name": "Party Animal Antle"
    },
    {
      "variant": "6b",
      "name": "Bruised Ego Baskin"
    },
    {
      "variant": "7b",
      "name": "Kindled Kirkham"
    },
    {
      "variant": "8b",
      "name": "Gunman Glover"
    },
    {
      "variant": "9b",
      "name": "Lowe Standards"
    },
    {
      "variant": "10b",
      "name": "Toxic Exotic"
    },
    {
      "variant": "11b",
      "name": "Tooth Fairy Finlay"
    },
    {
      "variant": "12b",
      "name": "Don't Vote Dial"
    },
    {
      "variant": "13b",
      "name": "Spiteful Saffery"
    },
    {
      "variant": "14b",
      "name": "Tiger Cage"
    },
    {
      "variant": "15b",
      "name": "Lounging Leo"
    }
  ]

function getNameByFullVariant (variant) {
    let n = names.filter(c => c.variant === variant);
    if (n && n.length >= 0) return n[0].name;
    return null;
}

function cardBuilder (nr, namevariant, rarity, fileExtension="jpg", hasMp4=false, hasWebm=false) {
  let extra= "";
  if (nr == 10 && namevariant == "a" && rarity.toLowerCase() == "prism") extra = "_CF";
    return {
        name: getNameByFullVariant(nr + namevariant),
        images:{
            front: `${IPFS_HASH}/${rarity.toLowerCase()}/${nr + namevariant}${extra}.${fileExtension}`,
            mp4: hasMp4 ? `${IPFS_HASH}/${rarity.toLowerCase()}/${nr + namevariant}.mp4` : null,
            webm: hasWebm ? `${IPFS_HASH}/${rarity.toLowerCase()}/${nr + namevariant}.webm` : null,
            back: `${IPFS_HASH}/back/${nr}.jpg`
        },
        variant: nr,
        namevariant,
        rarity: rarity
    }
}

function goldenCardBuilder (nr, namevariant) {
    let rarity="Golden";
    let ipfsHash = "Qmbxify5MLarmoxTZTCDzSsUY6tRFwt1zbRBeW3gowUNJX";
    return {
        name: getNameByFullVariant(nr + namevariant),
        images:{
            front: `${ipfsHash}/${nr + namevariant}.gif`,
            mp4: `${ipfsHash}/${nr + namevariant}.mp4`,
            webm: `${ipfsHash}/${nr + namevariant}.webm`,
            back: `QmcNrjmu8FFKdXNPygT9fCmJDfBHZWFBAkxTGy2T24Cp5S`
        },
        variant: nr,
        namevariant,
        rarity: rarity
    }
}


const nv = ["a", "b"]

const base = 15;
const prism = 15;
const sketch = 15;
const border = 15;
const collectors = [1,2,7];

const fullSeries = [];

for (let nvi = 0; nvi<nv.length; nvi++) {
    // Base
    for (let i = 0; i<(base.length ? base.length : base); i++) {
        let v = (base.length ? base[i] : (i+1));
        fullSeries.push(cardBuilder(v, nv[nvi], "Base", "jpg"))
    }
    // Prism
    for (let i = 0; i<(prism.length ? prism.length : prism); i++) {
        let v = (prism.length ? prism[i] : (i+1));
        fullSeries.push(cardBuilder(v, nv[nvi], "Prism", "gif", true, true))
    }
    // Border
    for (let i = 0; i<(border.length ? border.length : border); i++) {
        let v = (border.length ? border[i] : (i+1));
        fullSeries.push(cardBuilder(v, nv[nvi], "TigerBorder", "gif", true, true))
    }
    // Sketch
    for (let i = 0; i<(sketch.length ? sketch.length : sketch); i++) {
        let v = (sketch.length ? sketch[i] : (i+1));
        fullSeries.push(cardBuilder(v, nv[nvi], "TigerScratch", "gif", true, true))
    }
    // Collectors
    for (let i = 0; i<(collectors.length ? collectors.length : collectors); i++) {
        let v = (collectors.length ? collectors[i] : (i+1));
        fullSeries.push(cardBuilder(v, nv[nvi], "Collector", "gif", true, true))
    }
}

// fullSeries.push(goldenCardBuilder(4, "a"));
// fullSeries.push(goldenCardBuilder(41, "a"));
// fullSeries.push(goldenCardBuilder(5, "b"));
// fullSeries.push(goldenCardBuilder(35, "b"));

export default fullSeries;